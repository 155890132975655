import React from 'react';
import { 
  Typography, 
  Paper, 
  Grid, 
  Chip,
  LinearProgress,
  Container,
  useTheme,
  alpha,
  Tooltip,
  IconButton,
  Stack,
  Box,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SecurityIcon from '@mui/icons-material/Security';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PageHeader from '../../components/common/PageHeader';

interface ApplicationStatus {
  jobTitle: string;
  company: string;
  status: string;
  progress: number;
  appliedDate: string;
  location: string;
  clearanceLevel: string;
  salary: string;
}

type StatusColor = 'success' | 'primary' | 'info' | 'default';

const applications: ApplicationStatus[] = [
  {
    jobTitle: 'Senior Software Engineer',
    company: 'Tech Solutions Inc.',
    status: 'Under Review',
    progress: 40,
    appliedDate: '2024-01-15',
    location: 'Washington, DC',
    clearanceLevel: 'TS/SCI',
    salary: '$120,000 - $160,000'
  },
  {
    jobTitle: 'Systems Analyst',
    company: 'Defense Dynamics',
    status: 'Interview Scheduled',
    progress: 70,
    appliedDate: '2024-01-10',
    location: 'Arlington, VA',
    clearanceLevel: 'Secret',
    salary: '$90,000 - $120,000'
  },
  {
    jobTitle: 'Project Manager',
    company: 'Federal Systems LLC',
    status: 'Application Received',
    progress: 20,
    appliedDate: '2024-01-05',
    location: 'Alexandria, VA',
    clearanceLevel: 'Top Secret',
    salary: '$110,000 - $140,000'
  }
];

const getStatusColor = (status: string): StatusColor => {
  switch (status) {
    case 'Interview Scheduled':
      return 'success';
    case 'Under Review':
      return 'primary';
    case 'Application Received':
      return 'info';
    default:
      return 'default';
  }
};

const ApplicationTrackingPage: React.FC = () => {
  const theme = useTheme();

  const stats = [
    {
      label: 'Active Applications',
      count: applications.length,
      color: '#1976d2', // Blue for active applications
      icon: <AssignmentIcon sx={{ fontSize: 24 }} />,
      description: 'Total applications in progress',
    },
    {
      label: 'Interviews',
      count: applications.filter(app => app.status === 'Interview Scheduled').length,
      color: '#2e7d32', // Green for interviews (positive progress)
      icon: <EventAvailableIcon sx={{ fontSize: 24 }} />,
      description: 'Upcoming and completed interviews',
    },
    {
      label: 'Offers',
      count: 0,
      color: '#ed6c02', // Orange for offers (exciting opportunity)
      icon: <EmojiEventsIcon sx={{ fontSize: 24 }} />,
      description: 'Job offers received',
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader
        title="Application Tracking"
        subtitle="Monitor and manage your job applications"
        stats={[
          {
            value: applications.length,
            label: 'Total Applications',
            color: 'primary'
          },
          {
            value: applications.filter(app => app.status === 'Interview Scheduled').length,
            label: 'Interviews',
            color: 'success'
          },
          {
            value: `${(applications.filter(app => app.status === 'Accepted').length / applications.length * 100).toFixed(0)}%`,
            label: 'Success Rate',
            color: 'info'
          }
        ]}
      />
      <Stack spacing={3}>
        {applications.map((app, index) => (
          <Paper
            key={index}
            elevation={0}
            sx={{
              p: 3,
              borderRadius: 2,
              border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
              '&:hover': {
                boxShadow: theme.shadows[4],
                borderColor: 'primary.main',
              },
            }}
          >
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack spacing={0.5}>
                  <Typography variant="h6" component="h2">
                    {app.jobTitle}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <BusinessIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                    <Typography variant="body1" color="text.secondary" component="span">
                      {app.company}
                    </Typography>
                  </Stack>
                </Stack>
                <Chip
                  label={app.status}
                  color={getStatusColor(app.status)}
                  sx={{
                    fontWeight: 'medium',
                    px: 1,
                  }}
                />
              </Stack>

              <LinearProgress
                variant="determinate"
                value={app.progress}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                }}
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <CalendarTodayIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary" component="span">
                      Applied: {app.appliedDate}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <LocationOnIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary" component="span">
                      {app.location}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <SecurityIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary" component="span">
                      {app.clearanceLevel}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AttachMoneyIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary" component="span">
                      {app.salary}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Tooltip title="View Details">
                  <IconButton size="small" color="primary">
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Message Recruiter">
                  <IconButton size="small" color="primary">
                    <ChatIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Container>
  );
};

export default ApplicationTrackingPage;
