import React from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Divider,
  MenuItem,
} from '@mui/material';
import PageHeader from '../../components/common/PageHeader';
import EditIcon from '@mui/icons-material/Edit';

const CompanyPage: React.FC = () => {
  const companyStats = {
    activeJobs: 12,
    totalEmployees: 150,
    officeLocations: 3
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={0} sx={{ p: 4 }}>
        <PageHeader
          title="Company Profile"
          subtitle="Manage your company information and settings"
          stats={[
            {
              value: companyStats.activeJobs,
              label: 'Active Jobs',
              color: 'primary'
            },
            {
              value: companyStats.totalEmployees,
              label: 'Employees',
              color: 'success'
            },
            {
              value: companyStats.officeLocations,
              label: 'Locations',
              color: 'info'
            }
          ]}
          action={
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
            >
              Edit Profile
            </Button>
          }
        />
        <Divider sx={{ mb: 4 }} />
        
        <Grid container spacing={4}>
          {/* Company Information */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Company Information
            </Typography>
            <Box component="form" noValidate sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Company Name"
                margin="normal"
                defaultValue="AIRecruit Pros"
              />
              <TextField
                select
                fullWidth
                label="Industry"
                margin="normal"
                defaultValue=""
              >
                <MenuItem value="technology">Technology</MenuItem>
                <MenuItem value="defense">Defense & Government</MenuItem>
                <MenuItem value="healthcare">Healthcare</MenuItem>
                <MenuItem value="financial">Financial Services</MenuItem>
                <MenuItem value="manufacturing">Manufacturing</MenuItem>
                <MenuItem value="professional">Professional Services</MenuItem>
                <MenuItem value="commercial">Commercial & Enterprise</MenuItem>
              </TextField>
              <TextField
                fullWidth
                label="Company Website"
                margin="normal"
                defaultValue="https://airecruitpros.com"
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Company Description"
                margin="normal"
                defaultValue="AIRecruit Pros specializes in connecting cleared professionals with leading defense contractors and technology companies."
              />
            </Box>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <Box component="form" noValidate sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Business Email"
                margin="normal"
                defaultValue="contact@airecruitpros.com"
              />
              <TextField
                fullWidth
                label="Business Phone"
                margin="normal"
                defaultValue="(555) 123-4567"
              />
              <TextField
                fullWidth
                label="Business Address"
                margin="normal"
                defaultValue="123 Defense Drive"
              />
              <TextField
                fullWidth
                label="City"
                margin="normal"
                defaultValue="Arlington"
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="State"
                    margin="normal"
                    defaultValue="VA"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="ZIP Code"
                    margin="normal"
                    defaultValue="22201"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Save Button */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ minWidth: 200 }}
              >
                Save Changes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CompanyPage;
