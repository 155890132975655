import React from 'react';
import {
  Box,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import {
  Work as WorkIcon,
  Person as PersonIcon,
  Assessment as AssessmentIcon,
  Message as MessageIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

interface NotificationsMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  userType: 'recruiter' | 'candidate';
}

const getNotificationIcon = (type: string) => {
  switch (type) {
    case 'application':
      return <WorkIcon />;
    case 'candidate':
      return <PersonIcon />;
    case 'report':
      return <AssessmentIcon />;
    case 'message':
      return <MessageIcon />;
    default:
      return <MessageIcon />;
  }
};

const getRecentNotifications = (userType: string) => {
  if (userType === 'recruiter') {
    return [
      {
        id: 1,
        type: 'application',
        title: 'New Application Received',
        description: 'John Doe applied for Senior Software Engineer position',
        timestamp: new Date('2024-12-12T08:30:00'),
      },
      {
        id: 2,
        type: 'candidate',
        title: 'Candidate Update',
        description: 'Sarah Smith completed the technical assessment',
        timestamp: new Date('2024-12-11T15:45:00'),
      },
      {
        id: 3,
        type: 'report',
        title: 'Weekly Report Available',
        description: 'Your recruitment analytics report is ready',
        timestamp: new Date('2024-12-11T09:00:00'),
      },
    ];
  } else {
    return [
      {
        id: 1,
        type: 'application',
        title: 'Application Status Update',
        description: 'Your application has moved to interview stage',
        timestamp: new Date('2024-12-12T08:30:00'),
      },
      {
        id: 2,
        type: 'message',
        title: 'New Message',
        description: 'Tech Solutions Inc. sent you a message',
        timestamp: new Date('2024-12-11T15:45:00'),
      },
      {
        id: 3,
        type: 'report',
        title: 'Profile Match',
        description: 'New job matches your profile',
        timestamp: new Date('2024-12-11T09:00:00'),
      },
    ];
  }
};

const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
  anchorEl,
  onClose,
  userType,
}) => {
  const navigate = useNavigate();
  const notifications = getRecentNotifications(userType);

  const handleViewAll = () => {
    onClose();
    navigate(`/${userType}/notifications`);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 3,
        sx: {
          width: 360,
          maxHeight: 480,
          borderRadius: '12px',
          mt: 1,
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Recent Notifications
        </Typography>
      </Box>
      <Divider />
      <List sx={{ p: 0 }}>
        {notifications.map((notification, index) => (
          <React.Fragment key={notification.id}>
            {index > 0 && <Divider />}
            <ListItem sx={{ px: 2, py: 1.5 }}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                  {getNotificationIcon(notification.type)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={notification.title}
                secondary={
                  <React.Fragment>
                    <Typography variant="body2" color="text.primary" noWrap>
                      {notification.description}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {format(notification.timestamp, 'MMM d, h:mm a')}
                    </Typography>
                  </React.Fragment>
                }
                secondaryTypographyProps={{ component: 'div' }}
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
      <Divider />
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={handleViewAll}
          fullWidth
          sx={{ borderRadius: '8px' }}
        >
          View All Notifications
        </Button>
      </Box>
    </Popover>
  );
};

export default NotificationsMenu;
