import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tabs,
  Tab,
  Chip,
  Paper,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import PageHeader from '../../components/common/PageHeader';

export interface BaseNotification {
  id: number;
  title: string;
  description: string;
  timestamp: Date;
  read: boolean;
  priority: 'high' | 'medium' | 'low';
}

interface NotificationsPageProps<T extends BaseNotification> {
  notifications: T[];
  tabs: {
    label: string;
    value: string;
  }[];
  getNotificationIcon: (notification: T) => JSX.Element;
  onMarkAsRead?: (notification: T) => void;
  onDelete?: (notification: T) => void;
  userType: 'candidate' | 'recruiter';
  filterNotifications?: (notifications: T[], selectedTab: string) => T[];
}

export function NotificationsPage<T extends BaseNotification>({
  notifications = [],
  tabs = [],
  getNotificationIcon,
  onMarkAsRead,
  onDelete,
  userType,
  filterNotifications,
}: NotificationsPageProps<T>) {
  const [selectedTab, setSelectedTab] = React.useState(tabs.length > 0 ? tabs[0].value : 'all');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const displayedNotifications = filterNotifications 
    ? filterNotifications(notifications, selectedTab)
    : notifications;

  const unreadCount = notifications.filter(n => !n.read).length;

  return (
    <Box>
      <PageHeader
        title="Notifications"
        subtitle="Stay updated with your latest activities and updates"
        stats={[
          {
            value: notifications.length,
            label: 'Total',
            color: 'primary'
          },
          {
            value: notifications.filter(n => !n.read).length,
            label: 'Unread',
            color: 'warning'
          }
        ]}
        action={
          <Button
            variant="outlined"
            onClick={() => onMarkAsRead?.(notifications.find(n => !n.read))}
            disabled={!notifications.some(n => !n.read)}
          >
            Mark All Read
          </Button>
        }
      />
      <Paper 
        elevation={0} 
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden',
          border: '1px solid',
          borderColor: 'divider',
          mb: 3,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              textTransform: 'none',
              minWidth: 'auto',
              px: 3,
              py: 2,
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>

        <List sx={{ py: 0 }}>
          {displayedNotifications.map((notification, index) => (
            <React.Fragment key={notification.id}>
              {index > 0 && (
                <Box sx={{ mx: 2 }}>
                  <Box sx={{ borderTop: 1, borderColor: 'divider' }} />
                </Box>
              )}
              <ListItem
                sx={{
                  px: 3,
                  py: 2,
                  backgroundColor: notification.read ? 'inherit' : 'action.hover',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
                secondaryAction={
                  <IconButton edge="end" onClick={() => onDelete?.(notification)}>
                    <CloseIcon />
                  </IconButton>
                }
              >
                <Box sx={{ mr: 2, color: 'primary.main' }}>
                  {getNotificationIcon(notification)}
                </Box>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography 
                        variant="subtitle1"
                        sx={{ 
                          fontWeight: notification.read ? 400 : 600,
                          flex: 1,
                        }}
                      >
                        {notification.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                      >
                        {format(notification.timestamp, 'MMM d, h:mm a')}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mt: 0.5,
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      {notification.description}
                    </Typography>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
          {displayedNotifications.length === 0 && (
            <ListItem sx={{ py: 4 }}>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    align="center"
                    color="text.secondary"
                  >
                    No notifications to display
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
      </Paper>
    </Box>
  );
}

export default NotificationsPage;
