import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  useTheme,
  useMediaQuery,
  Badge,
  ListItemIcon,
  Container,
  Divider,
  Typography,
  Button,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
import SecurityIcon from '@mui/icons-material/Security';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupIcon from '@mui/icons-material/Group';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddIcon from '@mui/icons-material/Add';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import MessageIcon from '@mui/icons-material/Message';
import MailIcon from '@mui/icons-material/Mail';
import BusinessIcon from '@mui/icons-material/Business';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { styled } from '@mui/material/styles';
import NotificationsMenu from './NotificationsMenu';
import Logo from './Logo';

interface HeaderProps {
  userType: 'candidate' | 'recruiter';
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#fff',
  color: theme.palette.text.primary,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
}));

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  padding: '0 24px',
});

const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const NavContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

export const Header: React.FC<HeaderProps> = ({ userType }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = React.useState<null | HTMLElement>(null);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMobileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMobileMenuAnchorEl(null);
    setNotificationsAnchorEl(null);
    setProfileMenuAnchorEl(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    // TODO: Implement logout logic
    navigate('/');
    handleClose();
  };

  const candidateNavItems = [
    { icon: <HomeIcon />, label: 'Home', path: '/' },
    { icon: <DashboardIcon />, label: 'Dashboard', path: '/candidate/dashboard' },
    { icon: <WorkIcon />, label: 'Find Jobs', path: '/candidate/jobs' },
    { icon: <BookmarkIcon />, label: 'Saved Jobs', path: '/candidate/saved-jobs' },
    { icon: <AssignmentIcon />, label: 'Applications', path: '/candidate/applications' },
  ];

  const recruiterNavItems = [
    { icon: <HomeIcon />, label: 'Home', path: '/' },
    { icon: <DashboardIcon />, label: 'Dashboard', path: '/recruiter/dashboard' },
    { icon: <PersonSearchIcon />, label: 'Candidates', path: '/recruiter/candidates' },
    { icon: <WorkIcon />, label: 'Job Listings', path: '/recruiter/jobs' },
  ];

  const candidateMobileMenuItems = [
    {
      group: 'Main',
      items: [
        { icon: <HomeIcon />, label: 'Home', path: '/' },
        { icon: <DashboardIcon />, label: 'Dashboard', path: '/candidate/dashboard' },
      ]
    },
    {
      group: 'Jobs',
      items: [
        { icon: <WorkIcon />, label: 'Find Jobs', path: '/candidate/jobs' },
        { icon: <BookmarkIcon />, label: 'Saved Jobs', path: '/candidate/saved-jobs' },
        { icon: <AssignmentIcon />, label: 'Applications', path: '/candidate/applications' },
      ]
    },
    {
      group: 'Career Development',
      items: [
        { icon: <AssessmentIcon />, label: 'Skill Assessments', path: '/candidate/assessments' },
        { icon: <SchoolIcon />, label: 'Learning Path', path: '/candidate/learning-path' },
        { icon: <LibraryBooksIcon />, label: 'Career Resources', path: '/candidate/resources' },
        { icon: <SecurityIcon />, label: 'Clearance Guide', path: '/candidate/clearance-guide' },
      ]
    },
    {
      group: 'Documents & Reports',
      items: [
        { icon: <DescriptionIcon />, label: 'Documents', path: '/candidate/documents' },
        { icon: <AssessmentIcon />, label: 'Reports', path: '/candidate/reports' },
      ]
    },
    {
      group: 'Communication',
      items: [
        { icon: <MessageIcon />, label: 'Messages', path: '/candidate/messages' },
        { icon: <NotificationsIcon />, label: 'Notifications', path: '/candidate/notifications' },
      ]
    },
    {
      group: 'Settings',
      items: [
        { icon: <AccountCircleIcon />, label: 'Profile', path: '/candidate/profile' },
        { icon: <SettingsIcon />, label: 'Preferences', path: '/candidate/preferences' },
        { icon: <LogoutIcon />, label: 'Logout', onClick: handleLogout },
      ]
    }
  ];

  const recruiterMobileMenuItems = [
    {
      group: 'Main',
      items: [
        { icon: <HomeIcon />, label: 'Home', path: '/' },
        { icon: <DashboardIcon />, label: 'Dashboard', path: '/recruiter/dashboard' },
      ]
    },
    {
      group: 'Jobs',
      items: [
        { icon: <WorkIcon />, label: 'Job Listings', path: '/recruiter/jobs' },
        { icon: <AddIcon />, label: 'Post Job', path: '/recruiter/post-job' },
      ]
    },
    {
      group: 'Candidates',
      items: [
        { icon: <PersonSearchIcon />, label: 'Search Candidates', path: '/recruiter/candidates' },
        { icon: <VideoCallIcon />, label: 'Interviews', path: '/recruiter/interviews' },
        { icon: <AssessmentIcon />, label: 'Assessments', path: '/recruiter/assessments' },
      ]
    },
    {
      group: 'Communication',
      items: [
        { icon: <MessageIcon />, label: 'Messages', path: '/recruiter/messages' },
        { icon: <NotificationsIcon />, label: 'Notifications', path: '/recruiter/notifications' },
        { icon: <MailIcon />, label: 'Email Templates', path: '/recruiter/email-templates' },
      ]
    },
    {
      group: 'Organization',
      items: [
        { icon: <BusinessIcon />, label: 'Company', path: '/recruiter/company' },
        { icon: <GroupIcon />, label: 'Team', path: '/recruiter/team' },
        { icon: <AssessmentIcon />, label: 'Reports', path: '/recruiter/reports' },
      ]
    },
    {
      group: 'Settings',
      items: [
        { icon: <AccountCircleIcon />, label: 'Profile', path: '/recruiter/profile' },
        { icon: <SettingsIcon />, label: 'Settings', path: '/recruiter/settings' },
        { icon: <LogoutIcon />, label: 'Logout', onClick: handleLogout },
      ]
    }
  ];

  const navItems = userType === 'recruiter' ? recruiterNavItems : candidateNavItems;
  const mobileMenuItems = userType === 'recruiter' ? recruiterMobileMenuItems : candidateMobileMenuItems;

  return (
    <StyledAppBar position="fixed">
      <Container maxWidth="xl">
        <StyledToolbar>
          <LogoContainer>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Logo />
            </Link>
          </LogoContainer>

          {!isMobile && (
            <NavContainer>
              {navItems.map((item) => (
                <MenuItem
                  key={item.path}
                  onClick={() => handleNavigation(item.path)}
                  selected={location.pathname === item.path}
                  sx={{
                    borderRadius: 1,
                    '&.Mui-selected': {
                      backgroundColor: 'primary.light',
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 36 }}>{item.icon}</ListItemIcon>
                  {item.label}
                </MenuItem>
              ))}
              {userType === 'recruiter' && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => handleNavigation('/recruiter/post-job')}
                  sx={{ ml: 2 }}
                >
                  Post Job
                </Button>
              )}
            </NavContainer>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              color="inherit"
              onClick={() => handleNavigation(`/${userType}/notifications`)}
              size="large"
            >
              <Badge badgeContent={3} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              color="inherit"
              onClick={handleProfileMenuClick}
              size="large"
            >
              <AccountCircleIcon />
            </IconButton>

            {isMobile && (
              <IconButton
                color="inherit"
                onClick={handleMobileMenuClick}
                size="large"
                edge="end"
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>

          {/* Notifications Menu */}
          <NotificationsMenu
            anchorEl={notificationsAnchorEl}
            open={Boolean(notificationsAnchorEl)}
            onClose={handleClose}
          />

          {/* Profile Menu */}
          <Menu
            anchorEl={profileMenuAnchorEl}
            open={Boolean(profileMenuAnchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                mt: 1.5,
                minWidth: 200,
              },
            }}
          >
            {(userType === 'recruiter' ? [
              { icon: <AccountCircleIcon />, label: 'Profile', path: '/recruiter/profile' },
              { icon: <BusinessIcon />, label: 'Company', path: '/recruiter/company' },
              { icon: <GroupIcon />, label: 'Team', path: '/recruiter/team' },
              { icon: <SettingsIcon />, label: 'Settings', path: '/recruiter/settings' },
              { icon: <LogoutIcon />, label: 'Logout', onClick: handleLogout },
            ] : [
              { icon: <AccountCircleIcon />, label: 'Profile', path: '/candidate/profile' },
              { icon: <DescriptionIcon />, label: 'Documents', path: '/candidate/documents' },
              { icon: <AssessmentIcon />, label: 'Assessments', path: '/candidate/assessments' },
              { icon: <SettingsIcon />, label: 'Preferences', path: '/candidate/preferences' },
              { icon: <LogoutIcon />, label: 'Logout', onClick: handleLogout },
            ]).map((item, index) => (
              <MenuItem
                key={index}
                onClick={item.onClick || (() => handleNavigation(item.path))}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            ))}
          </Menu>

          {/* Mobile Menu */}
          <Menu
            anchorEl={mobileMenuAnchorEl}
            open={Boolean(mobileMenuAnchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                mt: 1.5,
                width: 280,
                maxHeight: '80vh',
                overflowY: 'auto',
              },
            }}
          >
            <Box>
              {mobileMenuItems.map((group, groupIndex) => (
                <Box key={group.group}>
                  {groupIndex > 0 && <Divider />}
                  <Typography
                    variant="overline"
                    sx={{
                      px: 2,
                      py: 1,
                      display: 'block',
                      color: 'text.secondary',
                    }}
                  >
                    {group.group}
                  </Typography>
                  {group.items.map((item, index) => (
                    <MenuItem
                      key={`${group.group}-${index}`}
                      onClick={item.onClick || (() => handleNavigation(item.path))}
                      selected={location.pathname === item.path}
                      sx={{
                        minHeight: 48,
                        px: 2,
                        py: 1,
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        {item.icon}
                      </ListItemIcon>
                      {item.label}
                    </MenuItem>
                  ))}
                </Box>
              ))}
            </Box>
          </Menu>
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Header;
