import { Job } from '../types';

export const sampleJobs: Job[] = [
  {
    id: 1,
    title: 'Senior Software Engineer',
    company: 'TechCorp Solutions',
    location: 'Washington, DC',
    salary: '$120,000 - $160,000',
    jobType: 'Full-Time',
    clearanceLevel: 'Top Secret/SCI',
    experience: 'Senior',
    description: 'Looking for an experienced software engineer with strong background in cloud computing and security clearance.',
    requiredSkills: [
      'Java',
      'Python',
      'AWS',
      'Cloud Architecture',
      'Microservices'
    ],
    benefits: [
      'Competitive salary',
      'Health, dental, and vision insurance',
      '401(k) matching',
      'Remote work options',
      'Professional development budget'
    ],
    postedDate: new Date('2024-12-01').toISOString(),
    responsibilities: [
      'Design and implement scalable software solutions',
      'Lead technical projects and mentor junior developers',
      'Collaborate with cross-functional teams',
      'Participate in code reviews and architectural decisions'
    ],
    qualifications: [
      'Bachelor\'s degree in Computer Science or related field',
      '5+ years of experience in software development',
      'Active TS/SCI clearance',
      'Experience with cloud platforms (AWS, Azure)',
      'Strong knowledge of Java, Python, or C++'
    ]
  },
  {
    id: 2,
    title: 'Cybersecurity Analyst',
    company: 'SecureDefense Inc.',
    location: 'Arlington, VA',
    salary: '$90,000 - $130,000',
    jobType: 'Full-Time',
    clearanceLevel: 'Secret',
    experience: 'Mid',
    description: 'Join our cybersecurity team to protect critical infrastructure and sensitive data.',
    requiredSkills: [
      'Network Security',
      'SIEM Tools',
      'Incident Response',
      'Vulnerability Assessment',
      'Security Compliance'
    ],
    benefits: [
      'Competitive salary',
      'Comprehensive benefits package',
      'Training and certification support',
      'Flexible work schedule',
      'Annual security conference attendance'
    ],
    postedDate: new Date('2024-12-05').toISOString(),
    responsibilities: [
      'Monitor security systems and networks',
      'Investigate security breaches',
      'Implement security measures',
      'Conduct security assessments'
    ],
    qualifications: [
      'Bachelor\'s degree in Cybersecurity or related field',
      '3+ years of experience in cybersecurity',
      'Active Secret clearance',
      'CISSP certification preferred',
      'Experience with SIEM tools'
    ]
  },
  {
    id: 3,
    title: 'Systems Administrator',
    company: 'GovTech Solutions',
    location: 'Alexandria, VA',
    salary: '$85,000 - $120,000',
    jobType: 'Full-Time',
    clearanceLevel: 'Top Secret',
    experience: 'Mid',
    description: 'Seeking an experienced systems administrator to manage and maintain our secure infrastructure.',
    requiredSkills: [
      'Windows Server',
      'Linux Administration',
      'Network Security',
      'Active Directory',
      'Cloud Infrastructure'
    ],
    benefits: [
      'Competitive compensation',
      'Full benefits package',
      'Education assistance',
      'Public transit benefits',
      'Gym membership'
    ],
    postedDate: new Date('2024-12-10').toISOString(),
    responsibilities: [
      'Maintain and optimize system infrastructure',
      'Implement security policies',
      'Manage user access and permissions',
      'Monitor system performance'
    ],
    qualifications: [
      'Bachelor\'s degree in IT or related field',
      '4+ years of systems administration experience',
      'Active Top Secret clearance',
      'Experience with Windows Server and Linux',
      'Strong networking knowledge'
    ]
  }
];
