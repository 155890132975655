import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActions, 
  Button, 
  Chip,
  Container,
  useTheme,
  alpha,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ArticleIcon from '@mui/icons-material/Article';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import PageHeader from '../../components/common/PageHeader';

const resources = [
  {
    title: 'Resume Writing Guide',
    description: 'Learn how to create a compelling resume for security clearance positions',
    category: 'Resume',
    type: 'Guide',
    readTime: '15 min',
    featured: true,
  },
  {
    title: 'Security Clearance Interview Prep',
    description: 'Common questions and best practices for clearance interviews',
    category: 'Interview',
    type: 'Video',
    readTime: '25 min',
    featured: true,
  },
  {
    title: 'Salary Negotiation Tips',
    description: 'How to negotiate your salary in cleared positions',
    category: 'Career Growth',
    type: 'Article',
    readTime: '10 min',
    featured: false,
  },
  {
    title: 'Clearance Level Comparison',
    description: 'Understanding different security clearance levels and requirements',
    category: 'Education',
    type: 'Guide',
    readTime: '20 min',
    featured: false,
  },
];

const getResourceIcon = (type: string) => {
  switch (type) {
    case 'Video':
      return <VideoLibraryIcon />;
    case 'Guide':
      return <MenuBookIcon />;
    case 'Article':
      return <ArticleIcon />;
    default:
      return <ArticleIcon />;
  }
};

const getTypeColor = (type: string) => {
  switch (type) {
    case 'Video':
      return 'primary';
    case 'Guide':
      return 'secondary';
    case 'Article':
      return 'info';
    default:
      return 'default';
  }
};

const CareerResourcesPage: React.FC = () => {
  const theme = useTheme();
  const [savedResources, setSavedResources] = useState<number[]>([]);

  const toggleSave = (index: number) => {
    setSavedResources(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  const resourceStats = {
    total: resources.length,
    saved: savedResources.length,
    featured: resources.filter(r => r.featured).length
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader
        title="Career Resources"
        subtitle="Enhance your career with our curated collection of professional resources"
        stats={[
          {
            value: resourceStats.total,
            label: 'Resources',
            color: 'primary'
          },
          {
            value: resourceStats.featured,
            label: 'Featured',
            color: 'info'
          },
          {
            value: resourceStats.saved,
            label: 'Saved',
            color: 'success'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: '#1a237e',
              '&:hover': {
                backgroundColor: '#0d47a1',
              }
            }}
          >
            Submit Resource
          </Button>
        }
      />

      <Grid container spacing={3}>
        {resources.map((resource, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                transition: 'transform 0.2s',
                border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                boxShadow: `0 4px 6px -1px ${alpha(theme.palette.primary.main, 0.1)}`,
                '&:hover': {
                  transform: 'translateY(-4px)',
                },
              }}
            >
              {resource.featured && (
                <Chip 
                  label="Featured"
                  color="primary"
                  size="small"
                  sx={{ 
                    position: 'absolute',
                    top: 12,
                    right: 12,
                    backgroundColor: theme.palette.primary.main,
                  }}
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ 
                      p: 1,
                      borderRadius: 1,
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.primary.main,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      {getResourceIcon(resource.type)}
                    </Box>
                    <Stack spacing={1} sx={{ flex: 1 }}>
                      <Typography component="div" variant="h6" sx={{ fontWeight: 600 }}>
                        {resource.title}
                      </Typography>
                      <Typography component="div" variant="body2" color="text.secondary">
                        {resource.description}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip 
                      label={resource.type}
                      color={getTypeColor(resource.type) as any}
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                    <Chip 
                      label={resource.category}
                      variant="outlined"
                      size="small"
                      sx={{ 
                        borderColor: alpha(theme.palette.primary.main, 0.3),
                        fontWeight: 500
                      }}
                    />
                    <Stack 
                      direction="row" 
                      spacing={0.5} 
                      alignItems="center"
                      sx={{ 
                        ml: 'auto',
                        color: 'text.secondary',
                      }}
                    >
                      <AccessTimeIcon sx={{ fontSize: 16 }} />
                      <Typography component="span" variant="caption">
                        {resource.readTime}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </CardContent>

              <CardActions sx={{ 
                borderTop: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                justifyContent: 'space-between',
                px: 2,
                py: 1.5,
              }}>
                <Button 
                  startIcon={<OpenInNewIcon />}
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }
                  }}
                >
                  View Resource
                </Button>
                <Tooltip title={savedResources.includes(index) ? "Remove from Saved" : "Save for Later"}>
                  <IconButton 
                    onClick={() => toggleSave(index)}
                    size="small"
                    sx={{ 
                      color: savedResources.includes(index) 
                        ? theme.palette.primary.main
                        : 'text.secondary',
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      }
                    }}
                  >
                    {savedResources.includes(index) ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CareerResourcesPage;
