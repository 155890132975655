import React from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  useTheme,
  alpha,
  Box,
  Stack,
  Paper,
  useMediaQuery
} from '@mui/material';

export interface StatItem {
  value: string | number;
  label: string;
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning';
}

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  stats?: StatItem[];
  action?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  stats,
  action
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
      }}>
        {/* Header Content */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between', 
          alignItems: { xs: 'flex-start', sm: 'center' },
          width: '100%',
          mb: 2,
          gap: { xs: 2, sm: 0 }
        }}>
          {/* Title and Subtitle */}
          <Box sx={{ mb: { xs: 1, sm: 0 } }}>
            <Typography 
              variant="h4" 
              sx={{ 
                color: theme.palette.primary.main,
                fontWeight: 600,
                mb: 1,
                fontSize: { xs: '1.5rem', sm: '2rem' }
              }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: 'text.secondary',
                  fontSize: { xs: '0.875rem', sm: '1rem' }
                }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>

          {/* Stats Container */}
          {(stats || action) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {stats && (
                <Paper 
                  elevation={0}
                  sx={{ 
                    display: 'flex', 
                    gap: { xs: 2, sm: 4 },
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #e0e0e0',
                    width: { xs: '100%', sm: 'auto' },
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {stats.map((stat, index) => (
                    <Box 
                      key={index} 
                      sx={{ 
                        textAlign: 'center',
                        minWidth: { xs: '60px', sm: '80px' }
                      }}
                    >
                      <Typography 
                        variant="h5" 
                        sx={{ 
                          fontWeight: 600,
                          color: stat.color ? theme.palette[stat.color].main : theme.palette.primary.main,
                          mb: 0.5,
                          fontSize: { xs: '1.25rem', sm: '1.5rem' }
                        }}
                      >
                        {stat.value}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: '#757575',
                          fontWeight: 500,
                          whiteSpace: 'nowrap',
                          fontSize: { xs: '0.75rem', sm: '0.875rem' }
                        }}
                      >
                        {stat.label}
                      </Typography>
                    </Box>
                  ))}
                </Paper>
              )}
            </Box>
          )}
        </Box>

        {/* Action Button */}
        {action && (
          <Box sx={{ 
            mt: 2,
            width: { xs: '100%', sm: 'auto' }
          }}>
            <Box sx={{ 
              '& .MuiButton-root': {
                height: '36px',
                padding: '0 16px',
                fontSize: '0.875rem',
                width: { xs: '100%', sm: 'auto' }
              }
            }}>
              {action}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PageHeader;
