import React from 'react';
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
  Button,
} from '@mui/material';
import {
  Work as WorkIcon,
  Person as PersonIcon,
  TrendingUp as TrendingUpIcon,
  Visibility as VisibilityIcon,
  Speed as SpeedIcon,
  EmojiEvents as TrophyIcon,
  Timeline as TimelineIcon,
  Assessment as AssessmentIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import PageHeader from '../../components/common/PageHeader';

const DashboardPage: React.FC = () => {
  const stats = [
    {
      title: 'Active Jobs',
      value: '12',
      icon: <WorkIcon sx={{ fontSize: 30, color: '#1a237e' }} />,
      change: '+2 this week',
    },
    {
      title: 'Total Applicants',
      value: '48',
      icon: <PersonIcon sx={{ fontSize: 30, color: '#1a237e' }} />,
      change: '+15 this week',
    },
    {
      title: 'Views This Week',
      value: '245',
      icon: <TrendingUpIcon sx={{ fontSize: 30, color: '#1a237e' }} />,
      change: '+28% vs last week',
    },
    {
      title: 'Conversion Rate',
      value: '68%',
      icon: <SpeedIcon sx={{ fontSize: 30, color: '#1a237e' }} />,
      change: '+5% vs last week',
    }
  ];

  const recentApplications = [
    {
      id: 1,
      name: 'John Smith',
      position: 'Senior Software Engineer',
      date: '2 hours ago',
      status: 'New',
      clearance: 'TS/SCI',
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      position: 'Systems Analyst',
      date: '5 hours ago',
      status: 'Reviewed',
      clearance: 'Secret',
    },
    {
      id: 3,
      name: 'Michael Brown',
      position: 'Network Engineer',
      date: '1 day ago',
      status: 'Interviewing',
      clearance: 'Top Secret',
    },
  ];

  return (
    <Box sx={{ py: 4, px: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <PageHeader
        title="Recruiter Dashboard"
        subtitle="Track your recruitment activities and candidate pipeline"
        stats={[
          {
            value: stats[0].value,
            label: 'Active Jobs',
            color: 'primary'
          },
          {
            value: stats[1].value,
            label: 'Applications',
            color: 'info'
          },
          {
            value: stats[2].value,
            label: 'Interviews',
            color: 'success'
          }
        ]}
      />
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              sx={{
                backgroundColor: '#fff',
                borderRadius: 2,
                p: 3,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#e8eaf6',
                  borderRadius: '50%',
                  width: 60,
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {stat.icon}
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    color: '#1a237e',
                    fontWeight: 700,
                    fontSize: '2rem',
                    lineHeight: 1,
                    mb: 0.5,
                  }}
                >
                  {stat.value}
                </Typography>
                <Typography
                  sx={{
                    color: '#666',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    mb: 0.5,
                  }}
                >
                  {stat.title}
                </Typography>
                <Typography
                  sx={{
                    color: '#2e7d32',
                    fontSize: '0.75rem',
                    fontWeight: 600,
                  }}
                >
                  {stat.change}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#1a237e',
                  fontWeight: 600,
                }}
              >
                Recent Applications
              </Typography>
              <Button
                component={RouterLink}
                to="/recruiter/candidates"
                sx={{
                  color: '#1a237e',
                  '&:hover': {
                    backgroundColor: '#e8eaf6',
                  },
                }}
              >
                View All
              </Button>
            </Box>
            <List sx={{ '& > li': { px: 0 } }}>
              {recentApplications.map((application) => (
                <ListItem
                  key={application.id}
                  sx={{
                    borderBottom: '1px solid #eee',
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: '#e8eaf6' }}>
                      <PersonIcon sx={{ color: '#1a237e' }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box component="span" sx={{ color: '#1a237e', fontWeight: 600 }}>
                        {application.name}
                      </Box>
                    }
                    secondary={
                      <Box>
                        <Typography component="span" sx={{ display: 'block', color: '#666' }}>
                          {application.position}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{ fontSize: '0.75rem', color: '#666' }}
                        >
                          {application.date}
                        </Typography>
                      </Box>
                    }
                  />
                  <Chip
                    label={application.status}
                    size="small"
                    sx={{
                      backgroundColor: '#e8eaf6',
                      color: '#1a237e',
                      fontWeight: 600,
                    }}
                  />
                  <IconButton
                    component={RouterLink}
                    to={`/recruiter/candidates/${application.id}`}
                    sx={{ ml: 1 }}
                  >
                    <VisibilityIcon sx={{ color: '#1a237e' }} />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#1a237e',
                fontWeight: 600,
                mb: 3,
              }}
            >
              Quick Actions
            </Typography>
            <Grid container spacing={2}>
              {[
                { icon: <WorkIcon />, label: 'Post New Job', link: '/recruiter/post-job' },
                { icon: <AssessmentIcon />, label: 'Create Assessment', link: '/recruiter/assessments' },
                { icon: <PersonIcon />, label: 'Search Candidates', link: '/recruiter/candidates/search' },
                { icon: <TrendingUpIcon />, label: 'View Reports', link: '/recruiter/reports' },
              ].map((action, index) => (
                <Grid item xs={6} key={index}>
                  <Button
                    component={RouterLink}
                    to={action.link}
                    variant="outlined"
                    startIcon={action.icon}
                    fullWidth
                    sx={{
                      color: '#1a237e',
                      borderColor: '#e8eaf6',
                      p: 2,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      '&:hover': {
                        backgroundColor: '#e8eaf6',
                        borderColor: '#1a237e',
                      },
                    }}
                  >
                    {action.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
