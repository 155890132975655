import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box component="footer" py={4} textAlign="center" bgcolor="grey.200" mt={4}>
      <Typography variant="body1">
        © {new Date().getFullYear()} AIRecruit Pros. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer; 