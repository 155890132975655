import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Chip,
  Paper,
  Button,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PageHeader from '../../components/common/PageHeader';

interface TeamMember {
  id: number;
  name: string;
  role: string;
  email: string;
  avatar: string;
  status: 'active' | 'away' | 'offline';
}

const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: 'John Smith',
    role: 'Senior Recruiter',
    email: 'john.smith@example.com',
    avatar: '/avatars/1.jpg',
    status: 'active',
  },
  {
    id: 2,
    name: 'Sarah Johnson',
    role: 'Technical Recruiter',
    email: 'sarah.j@example.com',
    avatar: '/avatars/2.jpg',
    status: 'away',
  },
  {
    id: 3,
    name: 'Michael Brown',
    role: 'HR Manager',
    email: 'michael.b@example.com',
    avatar: '/avatars/3.jpg',
    status: 'offline',
  },
];

const teamStats = {
  totalMembers: 12,
  activeRecruiters: 8,
  managers: 3
};

const TeamPage: React.FC = () => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'away':
        return 'warning';
      case 'offline':
        return 'default';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader
        title="Team Management"
        subtitle="Manage your recruitment team and permissions"
        stats={[
          {
            value: teamStats.totalMembers,
            label: 'Team Members',
            color: 'primary'
          },
          {
            value: teamStats.activeRecruiters,
            label: 'Recruiters',
            color: 'success'
          },
          {
            value: teamStats.managers,
            label: 'Managers',
            color: 'info'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<PersonAddIcon />}
          >
            Add Member
          </Button>
        }
      />
      <Grid container spacing={3}>
        {teamMembers.map((member) => (
          <Grid item xs={12} sm={6} md={4} key={member.id}>
            <Card 
              elevation={0}
              sx={{ 
                height: '100%', 
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
              }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar
                    src={member.avatar}
                    alt={member.name}
                    sx={{ width: 64, height: 64, mr: 2 }}
                  />
                  <Box>
                    <Typography variant="h6">{member.name}</Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {member.role}
                    </Typography>
                    <Chip
                      label={member.status}
                      size="small"
                      color={getStatusColor(member.status) as any}
                      sx={{ mt: 0.5 }}
                    />
                  </Box>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {member.email}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TeamPage;
