import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardPage from '../pages/recruiter/DashboardPage';
import PostJobPage from '../pages/recruiter/PostJobPage';
import CandidateSearchPage from '../pages/recruiter/CandidateSearchPage';
import JobListingsPage from '../pages/recruiter/JobListingsPage';
import CompanyPage from '../pages/recruiter/CompanyPage';
import NotificationsPage from '../pages/shared/NotificationsPage';
import CandidatesPage from '../pages/recruiter/CandidatesPage';
import CandidateDetailPage from '../pages/recruiter/CandidateDetailPage';
import InterviewsPage from '../pages/recruiter/InterviewsPage';
import MessagesPage from '../pages/recruiter/MessagesPage';
import EmailTemplatesPage from '../pages/recruiter/EmailTemplatesPage';
import TeamPage from '../pages/recruiter/TeamPage';
import RecruiterAssessmentsPage from '../pages/recruiter/AssessmentsPage';
import ReportsPage from '../pages/recruiter/ReportsPage';
import SettingsPage from '../pages/recruiter/SettingsPage';
import RecruiterProfilePage from '../pages/recruiter/ProfilePage';

export const recruiterRoutes = [
  {
    path: "",
    element: <Navigate to="dashboard" replace />
  },
  {
    path: "dashboard",
    element: <DashboardPage />
  },
  {
    path: "jobs",
    element: <JobListingsPage />
  },
  {
    path: "post-job",
    element: <PostJobPage />
  },
  {
    path: "candidates",
    element: <CandidatesPage />
  },
  {
    path: "candidates/search",
    element: <CandidateSearchPage />
  },
  {
    path: "candidates/:id",
    element: <CandidateDetailPage />
  },
  {
    path: "interviews",
    element: <InterviewsPage />
  },
  {
    path: "messages",
    element: <MessagesPage />
  },
  {
    path: "notifications",
    element: <NotificationsPage />
  },
  {
    path: "email-templates",
    element: <EmailTemplatesPage />
  },
  {
    path: "team",
    element: <TeamPage />
  },
  {
    path: "company",
    element: <CompanyPage />
  },
  {
    path: "assessments",
    element: <RecruiterAssessmentsPage />
  },
  {
    path: "reports",
    element: <ReportsPage />
  },
  {
    path: "settings",
    element: <SettingsPage />
  },
  {
    path: "profile",
    element: <RecruiterProfilePage />
  }
];
