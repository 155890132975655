import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Button,
  Chip,
  TextField,
  IconButton,
  useTheme,
  alpha,
  Avatar,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SecurityIcon from '@mui/icons-material/Security';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import PageHeader from '../../components/common/PageHeader';

interface Candidate {
  id: number;
  name: string;
  title: string;
  location: string;
  clearanceLevel: string;
  skills: string[];
  status: string;
  experience: string;
  availability: string;
}

const mockCandidates: Candidate[] = [
  {
    id: 1,
    name: 'John Smith',
    title: 'Software Engineer',
    location: 'Washington, DC',
    clearanceLevel: 'TS/SCI',
    skills: ['React', 'TypeScript', 'Node.js'],
    status: 'Available',
    experience: '5+ years',
    availability: 'Immediate',
  },
  {
    id: 2,
    name: 'Sarah Johnson',
    title: 'Systems Analyst',
    location: 'Arlington, VA',
    clearanceLevel: 'Secret',
    skills: ['Python', 'AWS', 'Data Analysis'],
    status: 'Interviewing',
    experience: '3-5 years',
    availability: '2 weeks',
  },
  {
    id: 3,
    name: 'Michael Chen',
    title: 'DevOps Engineer',
    location: 'Reston, VA',
    clearanceLevel: 'Top Secret',
    skills: ['Docker', 'Kubernetes', 'AWS'],
    status: 'Available',
    experience: '7+ years',
    availability: 'Immediate',
  },
];

const CandidatesPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(null);
  const [filters, setFilters] = useState({
    clearanceLevels: [] as string[],
    locations: [] as string[],
    statuses: [] as string[],
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (filterType: keyof typeof filters, value: string) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: prev[filterType].includes(value)
        ? prev[filterType].filter(item => item !== value)
        : [...prev[filterType], value],
    }));
  };

  const filteredCandidates = mockCandidates.filter(candidate => {
    const matchesSearch = searchTerm === '' || 
      candidate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.skills.some(skill => skill.toLowerCase().includes(searchTerm.toLowerCase())) ||
      candidate.location.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesClearance = filters.clearanceLevels.length === 0 || 
      filters.clearanceLevels.includes(candidate.clearanceLevel);

    const matchesLocation = filters.locations.length === 0 || 
      filters.locations.includes(candidate.location);

    const matchesStatus = filters.statuses.length === 0 || 
      filters.statuses.includes(candidate.status);

    return matchesSearch && matchesClearance && matchesLocation && matchesStatus;
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title="Candidates"
        subtitle="Browse and manage your candidate pipeline"
        stats={[
          {
            value: mockCandidates.length,
            label: 'Total Candidates',
            color: 'primary'
          },
          {
            value: mockCandidates.filter(c => c.status === 'Interviewing').length,
            label: 'In Interview',
            color: 'info'
          },
          {
            value: mockCandidates.filter(c => c.status === 'Available').length,
            label: 'Available',
            color: 'success'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {}}
          >
            Search Candidates
          </Button>
        }
      />
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          placeholder="Search candidates by name, skills, or location..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Grid container spacing={3}>
        {filteredCandidates.map((candidate) => (
          <Grid item xs={12} key={candidate.id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                '&:hover': { boxShadow: 6 }
              }}
              onClick={() => setSelectedCandidate(candidate)}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar 
                      sx={{ 
                        width: 56, 
                        height: 56,
                        bgcolor: 'primary.main',
                      }}
                    >
                      {candidate.name.split(' ').map(n => n[0]).join('')}
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography variant="h6">
                          {candidate.name}
                        </Typography>
                        <Typography color="text.secondary" gutterBottom>
                          {candidate.title}
                        </Typography>
                      </Box>
                      <Chip 
                        label={candidate.status}
                        color={candidate.status === 'Available' ? 'success' : 'warning'}
                        size="small"
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <SecurityIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                      <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                        {candidate.clearanceLevel}
                      </Typography>
                      <LocationOnIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                        {candidate.location}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      {candidate.skills.map((skill) => (
                        <Chip
                          key={skill}
                          label={skill}
                          size="small"
                          sx={{ mr: 1, mb: 1 }}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Filter Drawer */}
      <Drawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        PaperProps={{
          sx: { width: 320, p: 3 }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Filters</Typography>
          <IconButton onClick={() => setFilterDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Clearance Level</Typography>
        <FormGroup>
          {['Secret', 'Top Secret', 'TS/SCI'].map((level) => (
            <FormControlLabel
              key={level}
              control={
                <Checkbox
                  checked={filters.clearanceLevels.includes(level)}
                  onChange={() => handleFilterChange('clearanceLevels', level)}
                />
              }
              label={level}
            />
          ))}
        </FormGroup>

        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Location</Typography>
        <FormGroup>
          {['Washington, DC', 'Arlington, VA', 'Reston, VA'].map((location) => (
            <FormControlLabel
              key={location}
              control={
                <Checkbox
                  checked={filters.locations.includes(location)}
                  onChange={() => handleFilterChange('locations', location)}
                />
              }
              label={location}
            />
          ))}
        </FormGroup>

        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Status</Typography>
        <FormGroup>
          {['Available', 'Interviewing'].map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={filters.statuses.includes(status)}
                  onChange={() => handleFilterChange('statuses', status)}
                />
              }
              label={status}
            />
          ))}
        </FormGroup>
      </Drawer>

      {/* Candidate Detail Dialog */}
      <Dialog
        open={Boolean(selectedCandidate)}
        onClose={() => setSelectedCandidate(null)}
        maxWidth="md"
        fullWidth
      >
        {selectedCandidate && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">{selectedCandidate.name}</Typography>
                <IconButton onClick={() => setSelectedCandidate(null)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Professional Information</Typography>
                  <Typography variant="body1" gutterBottom>Title: {selectedCandidate.title}</Typography>
                  <Typography variant="body1" gutterBottom>Experience: {selectedCandidate.experience}</Typography>
                  <Typography variant="body1" gutterBottom>Clearance: {selectedCandidate.clearanceLevel}</Typography>
                  <Typography variant="body1" gutterBottom>Location: {selectedCandidate.location}</Typography>
                  <Typography variant="body1" gutterBottom>Availability: {selectedCandidate.availability}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Skills</Typography>
                  <Box sx={{ mt: 1 }}>
                    {selectedCandidate.skills.map((skill) => (
                      <Chip
                        key={skill}
                        label={skill}
                        sx={{ mr: 1, mb: 1 }}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedCandidate(null)}>Close</Button>
              <Button variant="contained" color="primary">Contact Candidate</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
  );
};

export default CandidatesPage;
