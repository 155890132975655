import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Button, 
  Box, 
  TextField, 
  InputAdornment,
  Card,
  CardContent,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  alpha,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import JobList from '../../components/JobList';
import PageHeader from '../../components/common/PageHeader';
import { Job } from '../../types';
import { sampleJobs } from '../../data/sampleData'; // TODO: Replace with API call

const JobListingsPage: React.FC = () => {
  const theme = useTheme();
  const [jobs, setJobs] = useState<Job[]>(sampleJobs);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('newest');
  const [perPage, setPerPage] = useState(20);

  // Calculate stats
  const activeJobs = jobs.filter(job => job.status === 'active').length;
  const totalApplicants = jobs.reduce((sum, job) => sum + (job.applicants || 0), 0);
  const fillRate = jobs.length > 0 ? Math.round((jobs.filter(job => job.status === 'filled').length / jobs.length) * 100) : 0;

  // TODO: Replace with actual API call
  useEffect(() => {
    // Simulating API call
    const filteredJobs = sampleJobs.filter(job => 
      job.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.company.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setJobs(filteredJobs);
  }, [searchQuery]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader
        title="Job Listings"
        subtitle="Manage and track all your job postings"
        stats={[
          {
            value: activeJobs,
            label: 'Active Jobs',
            color: 'primary'
          },
          {
            value: totalApplicants,
            label: 'Total Applicants',
            color: 'info'
          },
          {
            value: `${fillRate}%`,
            label: 'Fill Rate',
            color: 'success'
          }
        ]}
        action={
          <Button
            variant="contained"
            component={RouterLink}
            to="/recruiter/post-job"
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: '#1a237e',
              '&:hover': {
                backgroundColor: '#0d47a1',
              }
            }}
          >
            Post New Job
          </Button>
        }
      />

      {/* Search Section */}
      <Card 
        elevation={0}
        sx={{ 
          mb: 4, 
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          backdropFilter: 'blur(20px)',
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                placeholder="Search jobs by title, skills, or location..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<FilterListIcon />}
                sx={{
                  height: '100%',
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                Filters
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Results Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box>
          <Typography variant="h6" gutterBottom>
            All Job Listings
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Viewing 1 - {perPage} of {jobs.length}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Show</InputLabel>
            <Select
              value={perPage}
              label="Show"
              onChange={(e) => setPerPage(Number(e.target.value))}
            >
              <MenuItem value={10}>10 per page</MenuItem>
              <MenuItem value={20}>20 per page</MenuItem>
              <MenuItem value={50}>50 per page</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Sort by</InputLabel>
            <Select
              value={sortBy}
              label="Sort by"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value="newest">Newest First</MenuItem>
              <MenuItem value="oldest">Oldest First</MenuItem>
              <MenuItem value="applicants">Most Applicants</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box>

      {/* Job List */}
      <JobList jobs={jobs} />
    </Container>
  );
};

export default JobListingsPage;
