import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Stack,
  Divider,
  Button,
  Avatar,
  Tooltip,
  Link,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Job } from '../types';

interface JobCardProps {
  job: Job & {
    videoUrl?: string;
    videoThumbnail?: string;
    aboutUs?: string;
    socialMedia?: {
      facebook?: string;
      instagram?: string;
      twitter?: string;
      other?: string;
    };
    certificationDetails?: string[];
    experienceAlternatives?: string[];
    isOnsite?: boolean;
    recruiters?: Array<{
      name: string;
      avatar: string;
      role: string;
    }>;
    jobCategories?: string[];
    relatedLocations?: string[];
  };
}

const JobCard: React.FC<JobCardProps> = ({ job }) => {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = React.useState(false);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s, box-shadow 0.2s',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: 4,
        },
      }}
      onClick={() => navigate(`/jobs/${job.id}`)}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 600, color: 'primary.main' }}
        >
          {job.title}
        </Typography>

        {job.videoUrl && !showVideo && (
          <Box
            sx={{
              position: 'relative',
              mb: 2,
              cursor: 'pointer',
              '&:hover': { opacity: 0.9 },
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowVideo(true);
            }}
          >
            <img
              src={job.videoThumbnail}
              alt="Video thumbnail"
              style={{ width: '100%', borderRadius: '4px' }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
                p: 1,
              }}
            >
              <PlayArrowIcon sx={{ color: 'white', fontSize: 40 }} />
            </Box>
          </Box>
        )}

        {job.videoUrl && showVideo && (
          <Box sx={{ mb: 2 }}>
            <iframe
              width="100%"
              height="315"
              src={job.videoUrl}
              title="Company video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        )}

        <Stack spacing={2} sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <BusinessIcon color="action" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              {job.company}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LocationOnIcon color="action" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              {job.location} {job.isOnsite && "(ON/OFF SITE)"}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SecurityIcon color="action" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              {job.clearanceLevel}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AttachMoneyIcon color="action" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              {job.salary}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WorkIcon color="action" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              {job.jobType} · {job.experience} Level
            </Typography>
          </Box>
        </Stack>

        <Divider sx={{ my: 2 }} />

        {job.aboutUs && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {job.aboutUs}
            </Typography>
          </Box>
        )}

        {job.certificationDetails && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Required Certifications
            </Typography>
            {job.certificationDetails.map((cert, index) => (
              <Chip
                key={index}
                label={cert}
                size="small"
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>
        )}

        {job.experienceAlternatives && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Experience Requirements
            </Typography>
            {job.experienceAlternatives.map((exp, index) => (
              <Typography key={index} variant="body2" color="text.secondary">
                • {exp}
              </Typography>
            ))}
          </Box>
        )}

        <Box sx={{ mt: 'auto' }}>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                // Handle apply action
              }}
            >
              APPLY NOW
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                // Handle interest action
              }}
            >
              I'M INTERESTED
            </Button>
          </Stack>

          {job.recruiters && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Network Employers
              </Typography>
              <Stack direction="row" spacing={1}>
                {job.recruiters.map((recruiter, index) => (
                  <Tooltip key={index} title={`${recruiter.name} - ${recruiter.role}`}>
                    <Avatar src={recruiter.avatar} />
                  </Tooltip>
                ))}
              </Stack>
            </Box>
          )}

          {(job.jobCategories || job.relatedLocations) && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Related Links
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {job.jobCategories?.map((category, index) => (
                  <Link
                    key={`cat-${index}`}
                    href="#"
                    onClick={(e) => {
                      e.stopPropagation();
                      // Handle category click
                    }}
                  >
                    {category}
                  </Link>
                ))}
                {job.relatedLocations?.map((location, index) => (
                  <Link
                    key={`loc-${index}`}
                    href="#"
                    onClick={(e) => {
                      e.stopPropagation();
                      // Handle location click
                    }}
                  >
                    {location}
                  </Link>
                ))}
              </Stack>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default JobCard;