import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

interface LogoProps {
  color?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
}

const Logo: React.FC<LogoProps> = ({ 
  color = 'primary', 
  size = 'medium', 
  onClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [imageError, setImageError] = useState(false);

  // Original image ratio is 403:72 ≈ 5.6:1
  const getLogoSize = () => {
    switch (size) {
      case 'small':
        return { width: '180px', height: '32px' };
      case 'large':
        return { width: '300px', height: '54px' };
      default: // medium
        return { width: '240px', height: '43px' };
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    } else if (location.pathname !== '/') {
      navigate('/', { replace: true });
    }
  };

  const getImagePath = () => {
    const basePath = `${process.env.PUBLIC_URL}/images`;
    return imageError 
      ? `${basePath}/AIRecruit_Pros_Logo_4x4.png`
      : `${basePath}/AIRecruit_Pros_Logo.jpeg`;
  };

  return (
    <Box 
      onClick={handleClick}
      sx={{ 
        display: 'flex', 
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.9
        }
      }}
    >
      <Box
        component="img"
        src={getImagePath()}
        alt="AIRecruit Pros Logo"
        onError={() => setImageError(true)}
        sx={{
          ...getLogoSize(),
          filter: color === 'white' ? 'brightness(0) invert(1)' : 'none',
          objectFit: 'contain',
          backgroundColor: 'transparent',
          display: 'block',
          maxWidth: '100%',
          height: 'auto'
        }}
        loading="eager"
      />
    </Box>
  );
};

export default Logo;
