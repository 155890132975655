import React from 'react';
import { Box, Typography, Paper, Grid, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PageHeader from '../../components/common/PageHeader';

const MessagesPage: React.FC = () => {
  const messageStats = {
    totalMessages: 45,
    unread: 8,
    conversations: 15
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader
        title="Messages"
        subtitle="Communicate with candidates and team members"
        stats={[
          {
            value: messageStats.totalMessages,
            label: 'Total Messages',
            color: 'primary'
          },
          {
            value: messageStats.unread,
            label: 'Unread',
            color: 'warning'
          },
          {
            value: messageStats.conversations,
            label: 'Active Chats',
            color: 'info'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            New Message
          </Button>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>JS</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="John Smith"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Re: Interview Schedule
                      </Typography>
                      {" — Thank you for considering my application..."}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              {/* Add more message items as needed */}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessagesPage;
