import React from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  FormControl, 
  FormControlLabel, 
  Switch, 
  TextField,
  Slider,
  Chip,
  Button,
  Autocomplete,
  Container
} from '@mui/material';
import PageHeader from '../../components/common/PageHeader';

const locations = [
  'Washington, DC',
  'Arlington, VA',
  'Alexandria, VA',
  'Reston, VA',
  'Bethesda, MD',
];

const jobTypes = [
  'Full-time',
  'Contract',
  'Part-time',
  'Remote',
  'Hybrid',
];

const clearanceLevels = [
  'Secret',
  'Top Secret',
  'TS/SCI',
  'Public Trust',
];

const PreferencesPage: React.FC = () => {
  const [selectedJobTypes, setSelectedJobTypes] = React.useState<string[]>(['Full-time']);
  const [salaryRange, setSalaryRange] = React.useState<number[]>([80000, 160000]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader
        title="Job Preferences"
        subtitle="Customize your job search settings and notification preferences"
        stats={[
          {
            value: 0,
            label: 'Active Preferences',
            color: 'primary'
          },
          {
            value: 0,
            label: 'Alert Rules',
            color: 'info'
          }
        ]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Job Alert Settings
            </Typography>
            <Box sx={{ mb: 3 }}>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Email notifications for new matching jobs"
              />
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Weekly job digest"
              />
            </Box>

            <Typography variant="h6" gutterBottom>
              Preferred Locations
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Autocomplete
                multiple
                options={locations}
                defaultValue={['Washington, DC']}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Add locations"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Box>

            <Typography variant="h6" gutterBottom>
              Job Types
            </Typography>
            <Box sx={{ mb: 3 }}>
              {jobTypes.map((type) => (
                <Chip
                  key={type}
                  label={type}
                  onClick={() => {
                    if (selectedJobTypes.includes(type)) {
                      setSelectedJobTypes(selectedJobTypes.filter(t => t !== type));
                    } else {
                      setSelectedJobTypes([...selectedJobTypes, type]);
                    }
                  }}
                  color={selectedJobTypes.includes(type) ? 'primary' : 'default'}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>

            <Typography variant="h6" gutterBottom>
              Clearance Level
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Autocomplete
                multiple
                options={clearanceLevels}
                defaultValue={['Secret', 'Top Secret']}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select clearance levels"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Box>

            <Typography variant="h6" gutterBottom>
              Salary Range
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Slider
                value={salaryRange}
                onChange={(_, newValue) => setSalaryRange(newValue as number[])}
                valueLabelDisplay="auto"
                min={30000}
                max={250000}
                step={5000}
                valueLabelFormat={(value) => `$${value.toLocaleString()}`}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography color="textSecondary">
                  ${salaryRange[0].toLocaleString()}
                </Typography>
                <Typography color="textSecondary">
                  ${salaryRange[1].toLocaleString()}
                </Typography>
              </Box>
            </Box>

            <Button variant="contained" color="primary">
              Save Preferences
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PreferencesPage;
