import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  CircularProgress,
} from '@mui/material';
import { Add as AddIcon, EmojiEvents as TrophyIcon, Timeline as TimelineIcon, Speed as SpeedIcon } from '@mui/icons-material';
import PageHeader from '../../components/common/PageHeader'; // Assuming PageHeader is in the correct directory

interface Assessment {
  id: number;
  title: string;
  type: string;
  duration: string;
  skillsAssessed: string[];
  timesUsed: number;
  passRate: number;
}

const sampleAssessments: Assessment[] = [
  {
    id: 1,
    title: 'Full Stack Development Skills',
    type: 'Technical',
    duration: '60 minutes',
    skillsAssessed: ['React', 'Node.js', 'SQL', 'System Design'],
    timesUsed: 145,
    passRate: 68,
  },
  {
    id: 2,
    title: 'Cybersecurity Fundamentals',
    type: 'Technical',
    duration: '45 minutes',
    skillsAssessed: ['Network Security', 'Cryptography', 'Risk Assessment'],
    timesUsed: 98,
    passRate: 72,
  },
  {
    id: 3,
    title: 'Project Management',
    type: 'Soft Skills',
    duration: '30 minutes',
    skillsAssessed: ['Leadership', 'Communication', 'Problem Solving'],
    timesUsed: 76,
    passRate: 85,
  },
];

const AssessmentsPage: React.FC = () => {
  const totalTimesUsed = sampleAssessments.reduce((acc, curr) => acc + curr.timesUsed, 0);
  const averagePassRate = Math.round(
    sampleAssessments.reduce((acc, curr) => acc + curr.passRate, 0) / sampleAssessments.length
  );

  return (
    <Box sx={{ py: 4, px: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <PageHeader
        title="Skill Assessments"
        subtitle="Create and manage candidate skill assessments"
        stats={[
          {
            value: sampleAssessments.length,
            label: 'Total Assessments',
            color: 'primary'
          },
          {
            value: sampleAssessments.filter(a => a.type === 'Technical').length,
            label: 'Technical',
            color: 'success'
          },
          {
            value: totalTimesUsed,
            label: 'Completions',
            color: 'info'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Create Assessment
          </Button>
        }
      />

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <Box 
            sx={{ 
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
              }
            }}
          >
            <Box 
              sx={{ 
                backgroundColor: '#e8eaf6',
                borderRadius: '50%',
                width: 60,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <TrophyIcon sx={{ fontSize: 30, color: '#1a237e' }} />
            </Box>
            <Box>
              <Typography 
                variant="h3" 
                sx={{ 
                  color: '#1a237e',
                  fontWeight: 700,
                  fontSize: '2.5rem',
                  lineHeight: 1
                }}
              >
                {sampleAssessments.length}
              </Typography>
              <Typography 
                sx={{ 
                  color: '#666',
                  fontSize: '0.875rem',
                  fontWeight: 500
                }}
              >
                Total Assessments
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box 
            sx={{ 
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
              }
            }}
          >
            <Box 
              sx={{ 
                backgroundColor: '#e8eaf6',
                borderRadius: '50%',
                width: 60,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <TimelineIcon sx={{ fontSize: 30, color: '#1a237e' }} />
            </Box>
            <Box>
              <Typography 
                variant="h3" 
                sx={{ 
                  color: '#1a237e',
                  fontWeight: 700,
                  fontSize: '2.5rem',
                  lineHeight: 1
                }}
              >
                {totalTimesUsed}
              </Typography>
              <Typography 
                sx={{ 
                  color: '#666',
                  fontSize: '0.875rem',
                  fontWeight: 500
                }}
              >
                Total Times Used
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box 
            sx={{ 
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
              }
            }}
          >
            <Box 
              sx={{ 
                position: 'relative',
                width: 60,
                height: 60,
              }}
            >
              <CircularProgress
                variant="determinate"
                value={averagePassRate}
                size={60}
                thickness={4}
                sx={{
                  color: '#1a237e',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SpeedIcon sx={{ fontSize: 24, color: '#1a237e' }} />
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography 
                  variant="h3" 
                  sx={{ 
                    color: '#1a237e',
                    fontWeight: 700,
                    fontSize: '2.5rem',
                    lineHeight: 1
                  }}
                >
                  {averagePassRate}
                </Typography>
                <Typography 
                  sx={{ 
                    color: '#1a237e',
                    fontWeight: 700,
                    fontSize: '1.5rem',
                    ml: 0.5
                  }}
                >
                  %
                </Typography>
              </Box>
              <Typography 
                sx={{ 
                  color: '#666',
                  fontSize: '0.875rem',
                  fontWeight: 500
                }}
              >
                Average Pass Rate
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <List sx={{ 
        backgroundColor: '#fff',
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
        '& > li': {
          borderBottom: '1px solid #eee',
          '&:last-child': {
            borderBottom: 'none'
          }
        }
      }}>
        {sampleAssessments.map((assessment) => (
          <ListItem key={assessment.id} sx={{ py: 3, px: 3, display: 'block' }}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: '#1a237e',
                fontWeight: 600,
                mb: 2
              }}
            >
              {assessment.title}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2, flexWrap: 'wrap' }}>
              <Box
                sx={{
                  backgroundColor: assessment.type === 'Technical' ? '#1a237e' : '#2e7d32',
                  color: '#fff',
                  px: 2,
                  py: 0.5,
                  borderRadius: '16px',
                  fontSize: '0.875rem',
                }}
              >
                {assessment.type}
              </Box>
              <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  color: '#666',
                  px: 2,
                  py: 0.5,
                  borderRadius: '16px',
                  fontSize: '0.875rem',
                }}
              >
                {assessment.duration}
              </Box>
              <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  color: '#666',
                  px: 2,
                  py: 0.5,
                  borderRadius: '16px',
                  fontSize: '0.875rem',
                }}
              >
                {assessment.timesUsed} times used
              </Box>
              <Box
                sx={{
                  backgroundColor: '#e8f5e9',
                  color: '#2e7d32',
                  px: 2,
                  py: 0.5,
                  borderRadius: '16px',
                  fontSize: '0.875rem',
                }}
              >
                {assessment.passRate}% pass rate
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {assessment.skillsAssessed.map((skill) => (
                <Box
                  key={skill}
                  sx={{
                    backgroundColor: '#f5f5f5',
                    color: '#666',
                    px: 2,
                    py: 0.5,
                    borderRadius: '16px',
                    fontSize: '0.875rem',
                  }}
                >
                  {skill}
                </Box>
              ))}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AssessmentsPage;
