import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Chip,
  IconButton,
  Collapse,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

type FilterCategory = keyof FilterState;

interface FilterState {
  experience: string[];
  cities: string[];
  states: string[];
  clearance: string[];
  postedWithin: string[];
  salary: string[];
  securityRequirements: string[];
  workplaceType: string[];
  employmentType: string[];
  jobCategory: string[];
  education: string[];
  travelRequired: string[];
  citizenship: string[];
}

interface SearchFiltersProps {
  onFiltersChange: (filters: FilterState) => void;
}

const initialFilterState: FilterState = {
  experience: [],
  cities: [],
  states: [],
  clearance: [],
  postedWithin: [],
  salary: [],
  securityRequirements: [],
  workplaceType: [],
  employmentType: [],
  jobCategory: [],
  education: [],
  travelRequired: [],
  citizenship: [],
};

const SearchFilters: React.FC<SearchFiltersProps> = ({ onFiltersChange }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedFilters, setSelectedFilters] = useState<FilterState>(initialFilterState);
  const [isOpen, setIsOpen] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const experienceOptions = [
    { label: 'Student', count: 2 },
    { label: '<2 yrs exp', count: 57 },
    { label: '2+ yrs exp', count: 273 },
    { label: '5+ yrs exp', count: 1216 },
    { label: '10+ yrs exp', count: 622 },
    { label: 'Mgr/Director', count: 18 },
  ];

  const cityOptions = [
    { label: 'Aberdeen Proving Ground, MD', count: 208 },
    { label: 'Alexandria, VA', count: 186 },
    { label: 'Annapolis Junction, MD', count: 1202 },
    { label: 'Arlington, VA', count: 471 },
    { label: 'Augusta, GA', count: 81 },
    { label: 'Aurora, CO', count: 303 },
    { label: 'Baltimore, MD', count: 143 },
    { label: 'Bedford, MA', count: 97 },
  ];

  const stateOptions = [
    { label: 'Alabama', count: 569 },
    { label: 'Alaska', count: 40 },
    { label: 'Arizona', count: 222 },
    { label: 'Arkansas', count: 13 },
    { label: 'California', count: 1287 },
    { label: 'Colorado', count: 1195 },
    { label: 'Connecticut', count: 16 },
    { label: 'Delaware', count: 2 },
  ];

  const clearanceOptions = [
    { label: 'Unspecified', count: 261 },
    { label: 'DoE Q or L', count: 141 },
    { label: 'Dept of Homeland Security', count: 177 },
    { label: 'Public Trust', count: 1193 },
    { label: 'Confidential', count: 53 },
    { label: 'Secret', count: 7706 },
    { label: 'Top Secret', count: 3716 },
    { label: 'Top Secret/SCI', count: 7111 },
  ];

  const postedWithinOptions = [
    { label: 'Today', count: 17117 },
    { label: 'Last 3 days', count: 19987 },
    { label: 'Last 7 days', count: 20355 },
    { label: 'Within a month', count: 20620 },
    { label: 'Within 3 months', count: 20674 },
    { label: 'Within 6 months', count: 20674 },
    { label: 'Within 12 months', count: 20674 },
  ];

  const salaryOptions = [
    { label: '$30,000+', count: 20674 },
    { label: '$50,000+', count: 20500 },
    { label: '$75,000+', count: 19800 },
    { label: '$100,000+', count: 15000 },
    { label: '$125,000+', count: 10000 },
    { label: '$150,000+', count: 5000 },
    { label: '$200,000+', count: 1000 },
  ];

  const securityRequirements = [
    { label: 'Polygraph Required', count: 4521 },
    { label: 'CI Polygraph', count: 2341 },
    { label: 'Full Scope Polygraph', count: 2180 },
    { label: 'Drug Test Required', count: 12453 },
    { label: 'Background Check Required', count: 18234 },
  ];

  const workplaceTypes = [
    { label: 'Remote', count: 3245 },
    { label: 'Hybrid', count: 8765 },
    { label: 'On-site', count: 8664 },
  ];

  const employmentTypes = [
    { label: 'Full-time', count: 18234 },
    { label: 'Part-time', count: 1234 },
    { label: 'Contract', count: 987 },
    { label: 'Temporary', count: 219 },
  ];

  const jobCategories = [
    { label: 'Software Development', count: 5234 },
    { label: 'Cybersecurity', count: 4123 },
    { label: 'Network Engineering', count: 3211 },
    { label: 'Systems Administration', count: 2876 },
    { label: 'Program Management', count: 2543 },
    { label: 'Intelligence Analysis', count: 2234 },
    { label: 'Data Science', count: 1876 },
    { label: 'Cloud Engineering', count: 1654 },
    { label: 'DevOps', count: 1432 },
    { label: 'Technical Writing', count: 987 },
  ];

  const educationLevels = [
    { label: 'High School/GED', count: 15234 },
    { label: 'Associate Degree', count: 12453 },
    { label: "Bachelor's Degree", count: 18765 },
    { label: "Master's Degree", count: 8765 },
    { label: 'Doctorate', count: 2345 },
    { label: 'Certification', count: 9876 },
  ];

  const travelRequirements = [
    { label: 'No travel', count: 12453 },
    { label: 'Up to 25%', count: 5678 },
    { label: '25% - 50%', count: 3456 },
    { label: '50% - 75%', count: 2345 },
    { label: '75% or more', count: 1234 },
  ];

  const citizenshipRequirements = [
    { label: 'US Citizen', count: 18765 },
    { label: 'US Citizen - Born', count: 15234 },
    { label: 'US Citizen - Naturalized', count: 12453 },
    { label: 'Green Card Holder', count: 5678 },
    { label: 'Dual Citizenship', count: 3456 },
  ];

  useEffect(() => {
    const count = Object.values(selectedFilters).reduce(
      (acc, curr) => acc + curr.length,
      0
    );
    setActiveFilterCount(count);
    onFiltersChange(selectedFilters);
  }, [selectedFilters, onFiltersChange]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFilterChange = (category: FilterCategory, value: string) => {
    setSelectedFilters(prev => {
      const newFilters = { ...prev };
      if (newFilters[category].includes(value)) {
        newFilters[category] = newFilters[category].filter((v: string) => v !== value);
      } else {
        newFilters[category] = [...newFilters[category], value];
      }
      return newFilters;
    });
  };

  const clearAllFilters = () => {
    setSelectedFilters(initialFilterState);
  };

  const renderFilterSection = (
    title: string,
    options: { label: string; count: number }[],
    category: FilterCategory
  ) => (
    <Accordion
      expanded={expanded === category}
      onChange={handleChange(category)}
      elevation={0}
      sx={{
        '&:before': { display: 'none' },
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
        {selectedFilters[category].length > 0 && (
          <Chip
            size="small"
            label={selectedFilters[category].length}
            color="primary"
            sx={{ ml: 1 }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {options.map((option) => (
            <FormControlLabel
              key={option.label}
              control={
                <Checkbox
                  checked={selectedFilters[category].includes(option.label)}
                  onChange={() => handleFilterChange(category, option.label)}
                  size="small"
                />
              }
              label={
                <Typography variant="body2">
                  {option.label} ({option.count})
                </Typography>
              }
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Paper sx={{ position: 'relative', mb: 2 }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button
          startIcon={<FilterListIcon />}
          onClick={() => setIsOpen(!isOpen)}
          color="primary"
          variant={isOpen ? "contained" : "outlined"}
        >
          Filters {activeFilterCount > 0 && `(${activeFilterCount})`}
        </Button>
        {activeFilterCount > 0 && (
          <Button size="small" onClick={clearAllFilters} color="inherit">
            Clear All
          </Button>
        )}
      </Box>
      
      <Collapse in={isOpen}>
        <Box sx={{ p: 2 }}>
          {renderFilterSection('Experience', experienceOptions, 'experience')}
          {renderFilterSection('Security Clearance', clearanceOptions, 'clearance')}
          {renderFilterSection('Job Category', jobCategories, 'jobCategory')}
          {renderFilterSection('Work Location', workplaceTypes, 'workplaceType')}
          {renderFilterSection('Employment Type', employmentTypes, 'employmentType')}
          {renderFilterSection('Posted Within', postedWithinOptions, 'postedWithin')}
          {renderFilterSection('Salary Range', salaryOptions, 'salary')}
          {renderFilterSection('Security Requirements', securityRequirements, 'securityRequirements')}
          {renderFilterSection('Education', educationLevels, 'education')}
          {renderFilterSection('Travel Required', travelRequirements, 'travelRequired')}
          {renderFilterSection('Citizenship', citizenshipRequirements, 'citizenship')}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default SearchFilters;
