import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Chip,
  Card,
  CardContent,
  useTheme,
  alpha,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Stack,
} from '@mui/material';
import {
  Description as DescriptionIcon,
  Upload as UploadIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
  MoreVert as MoreVertIcon,
  PictureAsPdf as PdfIcon,
  Image as ImageIcon,
  InsertDriveFile as FileIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import PageHeader from '../../components/common/PageHeader';

// Sample data for documents
const documents = [
  {
    id: 1,
    name: 'Resume_2024.pdf',
    type: 'Resume',
    lastModified: '2024-01-05',
    size: '245 KB',
    format: 'pdf',
  },
  {
    id: 2,
    name: 'CoverLetter_SoftwareEngineer.pdf',
    type: 'Cover Letter',
    lastModified: '2024-01-05',
    size: '125 KB',
    format: 'pdf',
  },
  {
    id: 3,
    name: 'SecurityClearance_2024.pdf',
    type: 'Clearance Document',
    lastModified: '2024-01-02',
    size: '1.2 MB',
    format: 'pdf',
  },
  {
    id: 4,
    name: 'Certifications.pdf',
    type: 'Certification',
    lastModified: '2023-12-28',
    size: '2.1 MB',
    format: 'pdf',
  },
];

const getFileIcon = (format: string) => {
  switch (format.toLowerCase()) {
    case 'pdf':
      return <PdfIcon />;
    case 'jpg':
    case 'png':
    case 'jpeg':
      return <ImageIcon />;
    default:
      return <FileIcon />;
  }
};

const DocumentsPage: React.FC = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDoc, setSelectedDoc] = useState<number | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleUpload = () => {
    // Implement file upload logic
    console.log('Upload clicked');
  };

  const handleDelete = (id: number) => {
    setDeleteDialogOpen(false);
    console.log('Delete clicked for id:', id);
  };

  const handleEdit = (id: number) => {
    setAnchorEl(null);
    console.log('Edit clicked for id:', id);
  };

  const handleDownload = (id: number) => {
    setAnchorEl(null);
    console.log('Download clicked for id:', id);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedDoc(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedDoc(null);
  };

  const handleDeleteClick = () => {
    setAnchorEl(null);
    setDeleteDialogOpen(true);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader
        title="My Documents"
        subtitle="Manage your professional documents and credentials"
        stats={[
          {
            value: documents.length,
            label: 'Total Documents',
            color: 'primary'
          },
          {
            value: `${documents.reduce((acc, doc) => acc + parseFloat(doc.size), 0).toFixed(1)} MB`,
            label: 'Total Size',
            color: 'info'
          }
        ]}
      />

      {/* Upload Section */}
      <Card 
        sx={{ 
          mb: 4,
          border: `2px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
          backgroundColor: alpha(theme.palette.primary.main, 0.02),
          transition: 'all 0.2s',
          cursor: 'pointer',
          '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
          }
        }}
        onClick={handleUpload}
      >
        <CardContent sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          py: 4,
        }}>
          <Stack spacing={2} alignItems="center">
            <CloudUploadIcon 
              sx={{ 
                fontSize: 48, 
                color: theme.palette.primary.main,
              }} 
            />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Drop files here or click to upload
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Supports PDF, DOC, DOCX up to 10MB
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      {/* Documents List */}
      <Card sx={{ 
        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        boxShadow: `0 4px 6px -1px ${alpha(theme.palette.primary.main, 0.1)}`,
      }}>
        <List>
          {documents.map((doc, index) => (
            <React.Fragment key={doc.id}>
              {index > 0 && (
                <Divider component="li" sx={{ borderColor: alpha(theme.palette.primary.main, 0.1) }} />
              )}
              <ListItem
                sx={{
                  py: 2,
                  transition: 'background-color 0.2s',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.02),
                  }
                }}
              >
                <ListItemIcon>
                  <Box sx={{ 
                    color: theme.palette.primary.main,
                    display: 'flex',
                    p: 1,
                    borderRadius: 1,
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  }}>
                    {getFileIcon(doc.format)}
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="span" sx={{ fontWeight: 600 }}>
                      {doc.name}
                    </Typography>
                  }
                  secondary={
                    <Stack component="span" spacing={2}>
                      <Stack component="span" direction="row" spacing={2} alignItems="center">
                        <Chip 
                          label={doc.type}
                          size="small"
                          sx={{ 
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                            color: theme.palette.primary.main,
                            fontWeight: 500,
                          }}
                        />
                        <Typography component="span" variant="body2" color="text.secondary">
                          {doc.size}
                        </Typography>
                        <Typography component="span" variant="body2" color="text.secondary">
                          Modified: {new Date(doc.lastModified).toLocaleDateString()}
                        </Typography>
                      </Stack>
                    </Stack>
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title="More Options">
                    <IconButton 
                      edge="end" 
                      onClick={(e) => handleMenuOpen(e, doc.id)}
                      sx={{ 
                        color: theme.palette.primary.main,
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        }
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Card>

      {/* Actions Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
            mt: 1,
          },
        }}
      >
        <MenuItem onClick={() => handleDownload(selectedDoc!)}>
          <DownloadIcon sx={{ mr: 2 }} /> Download
        </MenuItem>
        <MenuItem onClick={() => handleEdit(selectedDoc!)}>
          <EditIcon sx={{ mr: 2 }} /> Rename
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ color: theme.palette.error.main }}>
          <DeleteIcon sx={{ mr: 2 }} /> Delete
        </MenuItem>
      </Menu>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: '100%',
            maxWidth: 400,
          }
        }}
      >
        <DialogTitle sx={{ fontWeight: 600 }}>
          Delete Document
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              Are you sure you want to delete this document? This action cannot be undone.
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            sx={{ 
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleDelete(selectedDoc!)}
            variant="contained"
            color="error"
            sx={{
              boxShadow: 'none',
              '&:hover': {
                boxShadow: `0 4px 8px ${alpha(theme.palette.error.main, 0.2)}`,
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DocumentsPage;
