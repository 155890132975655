import React from 'react';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Work as WorkIcon,
  Bookmark as BookmarkIcon,
  Person as PersonIcon,
  Star as StarIcon,
  ArrowForward as ArrowForwardIcon,
  Timeline as TimelineIcon,
  Assignment as AssignmentIcon,
  Speed as SpeedIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import PageHeader from '../../components/common/PageHeader';

// Sample data for the dashboard
const stats = {
  profileViews: 124,
  savedJobs: 15,
  applications: 8,
  profileCompletion: 85,
};

const recentJobViews = [
  {
    title: 'Senior Software Engineer',
    company: 'Tech Solutions Inc.',
    location: 'Washington, DC',
    clearance: 'TS/SCI',
    salary: '$120,000 - $160,000',
    viewedAt: '2 days ago',
  },
  {
    title: 'Systems Analyst',
    company: 'Defense Dynamics',
    location: 'Arlington, VA',
    clearance: 'Secret',
    salary: '$90,000 - $120,000',
    viewedAt: '3 days ago',
  },
];

const recommendedActions = [
  {
    title: 'Complete Your Profile',
    description: 'Add your work experience and education to stand out',
    progress: 85,
    action: 'Complete Now',
    link: '/candidate/profile',
  },
  {
    title: 'Take Skill Assessments',
    description: 'Demonstrate your expertise to potential employers',
    progress: 40,
    action: 'Start Assessment',
    link: '/candidate/assessments',
  },
];

const DashboardPage: React.FC = () => {
  return (
    <Box sx={{ py: 4, px: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <PageHeader
        title="Welcome back, John"
        subtitle="Your job search is looking good today"
        stats={[
          {
            value: stats.profileViews,
            label: 'Profile Views',
            color: 'primary'
          },
          {
            value: stats.savedJobs,
            label: 'Saved Jobs',
            color: 'info'
          },
          {
            value: `${stats.profileCompletion}%`,
            label: 'Profile Complete',
            color: 'success'
          }
        ]}
      />

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e8eaf6',
                borderRadius: '50%',
                width: 60,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <VisibilityIcon sx={{ fontSize: 30, color: '#1a237e' }} />
            </Box>
            <Box>
              <Typography
                variant="h3"
                sx={{
                  color: '#1a237e',
                  fontWeight: 700,
                  fontSize: { xs: '1.5rem', sm: '2rem' },
                  lineHeight: 1,
                  mb: 0.5,
                }}
              >
                {stats.profileViews}
              </Typography>
              <Typography
                sx={{
                  color: '#666',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  fontWeight: 500,
                }}
              >
                Profile Views
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e8eaf6',
                borderRadius: '50%',
                width: 60,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BookmarkIcon sx={{ fontSize: 30, color: '#1a237e' }} />
            </Box>
            <Box>
              <Typography
                variant="h3"
                sx={{
                  color: '#1a237e',
                  fontWeight: 700,
                  fontSize: { xs: '1.5rem', sm: '2rem' },
                  lineHeight: 1,
                  mb: 0.5,
                }}
              >
                {stats.savedJobs}
              </Typography>
              <Typography
                sx={{
                  color: '#666',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  fontWeight: 500,
                }}
              >
                Saved Jobs
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e8eaf6',
                borderRadius: '50%',
                width: 60,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AssignmentIcon sx={{ fontSize: 30, color: '#1a237e' }} />
            </Box>
            <Box>
              <Typography
                variant="h3"
                sx={{
                  color: '#1a237e',
                  fontWeight: 700,
                  fontSize: { xs: '1.5rem', sm: '2rem' },
                  lineHeight: 1,
                  mb: 0.5,
                }}
              >
                {stats.applications}
              </Typography>
              <Typography
                sx={{
                  color: '#666',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  fontWeight: 500,
                }}
              >
                Applications
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
              },
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: 60,
                height: 60,
              }}
            >
              <CircularProgress
                variant="determinate"
                value={stats.profileCompletion}
                size={60}
                thickness={4}
                sx={{
                  color: '#1a237e',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SpeedIcon sx={{ fontSize: 24, color: '#1a237e' }} />
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h3"
                sx={{
                  color: '#1a237e',
                  fontWeight: 700,
                  fontSize: { xs: '1.5rem', sm: '2rem' },
                  lineHeight: 1,
                  mb: 0.5,
                }}
              >
                {stats.profileCompletion}%
              </Typography>
              <Typography
                sx={{
                  color: '#666',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  fontWeight: 500,
                }}
              >
                Profile Complete
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#1a237e',
                  fontWeight: 600,
                }}
              >
                Recently Viewed Jobs
              </Typography>
              <Button
                component={RouterLink}
                to="/candidate/jobs"
                sx={{
                  color: '#1a237e',
                  '&:hover': {
                    backgroundColor: '#e8eaf6',
                  },
                }}
              >
                View All Jobs
              </Button>
            </Box>
            <List>
              {recentJobViews.map((job, index) => (
                <ListItem
                  key={index}
                  sx={{
                    borderBottom: '1px solid #eee',
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Stack spacing={1}>
                        <Typography
                          component="span"
                          sx={{
                            color: '#1a237e',
                            fontWeight: 600,
                            fontSize: { xs: '1rem', sm: '1.1rem' },
                          }}
                        >
                          {job.title}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            color: '#666',
                            fontSize: { xs: '0.875rem', sm: '0.9rem' },
                          }}
                        >
                          {job.company} • {job.location}
                        </Typography>
                      </Stack>
                    }
                    secondary={
                      <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center" sx={{ mt: 1 }}>
                        <Chip
                          label={job.clearance}
                          size="small"
                          sx={{
                            backgroundColor: '#e8eaf6',
                            color: '#1a237e',
                            fontWeight: 500,
                          }}
                        />
                        <Chip
                          label={job.salary}
                          size="small"
                          sx={{
                            backgroundColor: '#e8f5e9',
                            color: '#2e7d32',
                            fontWeight: 500,
                          }}
                        />
                        <Typography
                          component="span"
                          sx={{
                            color: '#666',
                            fontSize: { xs: '0.75rem', sm: '0.875rem' },
                          }}
                        >
                          Viewed {job.viewedAt}
                        </Typography>
                      </Stack>
                    }
                  />
                  <IconButton
                    component={RouterLink}
                    to={`/candidate/jobs/${index}`}
                    sx={{
                      color: '#1a237e',
                      '&:hover': {
                        backgroundColor: '#e8eaf6',
                      },
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 3,
              height: '100%',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{
                color: '#1a237e',
                fontWeight: 600,
                mb: 3,
              }}
            >
              Recommended Actions
            </Typography>
            <Stack spacing={3}>
              {recommendedActions.map((action, index) => (
                <Stack key={index} spacing={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography
                      component="span"
                      sx={{
                        color: '#1a237e',
                        fontWeight: 600,
                        fontSize: { xs: '1rem', sm: '1.1rem' },
                      }}
                    >
                      {action.title}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        color: '#666',
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                      }}
                    >
                      {action.progress}%
                    </Typography>
                  </Stack>
                  <Typography
                    component="div"
                    variant="body2"
                    sx={{
                      color: '#666',
                      mb: 2,
                    }}
                  >
                    {action.description}
                  </Typography>
                  <Button
                    component={RouterLink}
                    to={action.link}
                    variant="outlined"
                    size="small"
                    sx={{
                      color: '#1a237e',
                      borderColor: '#1a237e',
                      '&:hover': {
                        backgroundColor: '#e8eaf6',
                        borderColor: '#1a237e',
                      },
                    }}
                  >
                    {action.action}
                  </Button>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
