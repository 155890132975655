import React from 'react';
import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import HomePage from "../pages/HomePage";
import CandidateLayout from "../layouts/CandidateLayout";
import RecruiterLayout from "../layouts/RecruiterLayout";
import NotFoundPage from "../pages/NotFoundPage";
import { candidateRoutes } from './candidateRoutes';
import { recruiterRoutes } from './recruiterRoutes';

const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/candidate",
    element: <CandidateLayout />,
    children: candidateRoutes,
  },
  {
    path: "/recruiter",
    element: <RecruiterLayout />,
    children: recruiterRoutes,
  },
  // Redirect legacy routes to new paths
  {
    path: "/jobs",
    element: <Navigate to="/candidate/jobs" replace />,
  },
  {
    path: "/jobs/:id",
    element: <Navigate to="/candidate/jobs/:id" replace />,
  },
  {
    path: "/saved-jobs",
    element: <Navigate to="/candidate/saved-jobs" replace />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export const router = createBrowserRouter(routes);
