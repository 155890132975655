import React, { useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  TextField,
  IconButton,
  Stack,
  Box,
  useTheme,
  alpha,
} from '@mui/material';
import {
  Send as SendIcon,
  Search as SearchIcon,
  Business as BusinessIcon,
} from '@mui/icons-material';
import PageHeader from '../../components/common/PageHeader';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

// Sample data for messages
const conversations = [
  {
    id: 1,
    recruiter: {
      name: 'Sarah Johnson',
      company: 'Tech Solutions Inc.',
      avatar: '',
    },
    lastMessage: 'Thanks for your interest in the Senior Software Engineer position...',
    timestamp: '10:30 AM',
    unread: true,
  },
  {
    id: 2,
    recruiter: {
      name: 'Michael Chen',
      company: 'Defense Dynamics',
      avatar: '',
    },
    lastMessage: 'Would you be available for an interview next week?',
    timestamp: 'Yesterday',
    unread: false,
  },
  {
    id: 3,
    recruiter: {
      name: 'Emily Williams',
      company: 'Federal Systems LLC',
      avatar: '',
    },
    lastMessage: 'I have reviewed your application and would like to discuss...',
    timestamp: '2 days ago',
    unread: false,
  },
];

// Sample message history
const messageHistory = [
  {
    id: 1,
    sender: 'Sarah Johnson',
    content: 'Hi John, thanks for your interest in the Senior Software Engineer position at Tech Solutions Inc.',
    timestamp: '10:30 AM',
    isRecruiter: true,
  },
  {
    id: 2,
    sender: 'Me',
    content: 'Thank you for reaching out! I am very interested in learning more about the role.',
    timestamp: '10:35 AM',
    isRecruiter: false,
  },
  {
    id: 3,
    sender: 'Sarah Johnson',
    content: 'Great! I would love to schedule a brief call to discuss your experience and the position in more detail.',
    timestamp: '10:40 AM',
    isRecruiter: true,
  },
];

const MessagesPage: React.FC = () => {
  const theme = useTheme();
  const [selectedConversation, setSelectedConversation] = useState(conversations[0]);
  const [messageInput, setMessageInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [newMessageOpen, setNewMessageOpen] = useState(false);

  const handleSendMessage = () => {
    if (messageInput.trim()) {
      // Add message sending logic here
      console.log('Sending message:', messageInput);
      setMessageInput('');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader
        title="Messages"
        subtitle="Stay connected with recruiters and manage your conversations"
        stats={[
          {
            value: conversations.length,
            label: 'Active Chats',
            color: 'primary'
          },
          {
            value: conversations.filter(c => !c.unread).length,
            label: 'Unread',
            color: 'warning'
          }
        ]}
        action={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setNewMessageOpen(true)}
          >
            New Message
          </Button>
        }
      />
      <Paper sx={{ display: 'flex', height: '70vh' }}>
        {/* Conversations List */}
        <Box sx={{ width: 320, borderRight: `1px solid ${theme.palette.divider}` }}>
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search conversations..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
              }}
            />
          </Box>
          <Divider />
          <List sx={{ overflow: 'auto', maxHeight: 'calc(70vh - 70px)' }}>
            {conversations.map((conversation) => (
              <React.Fragment key={conversation.id}>
                <ListItem
                  button
                  selected={selectedConversation.id === conversation.id}
                  onClick={() => setSelectedConversation(conversation)}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>{conversation.recruiter.name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" component="span">
                          {conversation.recruiter.name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {conversation.timestamp}
                        </Typography>
                      </Stack>
                    }
                    secondary={
                      <Stack spacing={0.5}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <BusinessIcon sx={{ fontSize: 14, color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">
                            {conversation.recruiter.company}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="body2"
                          color={conversation.unread ? 'primary' : 'text.secondary'}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            fontWeight: conversation.unread ? 'bold' : 'normal',
                          }}
                        >
                          {conversation.lastMessage}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        </Box>

        {/* Message Content */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {/* Conversation Header */}
          <Box sx={{ p: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar>{selectedConversation.recruiter.name[0]}</Avatar>
              <Stack>
                <Typography variant="subtitle1">
                  {selectedConversation.recruiter.name}
                </Typography>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <BusinessIcon sx={{ fontSize: 14, color: 'text.secondary' }} />
                  <Typography variant="caption" color="text.secondary">
                    {selectedConversation.recruiter.company}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>

          {/* Messages */}
          <Box sx={{ flex: 1, overflow: 'auto', p: 2, backgroundColor: alpha(theme.palette.background.default, 0.5) }}>
            <Stack spacing={2}>
              {messageHistory.map((message) => (
                <Box
                  key={message.id}
                  sx={{
                    display: 'flex',
                    justifyContent: message.isRecruiter ? 'flex-start' : 'flex-end',
                  }}
                >
                  <Paper
                    sx={{
                      p: 2,
                      maxWidth: '70%',
                      backgroundColor: message.isRecruiter
                        ? theme.palette.background.paper
                        : theme.palette.primary.main,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ color: message.isRecruiter ? 'text.primary' : 'white' }}
                    >
                      {message.content}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'block',
                        textAlign: 'right',
                        mt: 0.5,
                        color: message.isRecruiter ? 'text.secondary' : 'rgba(255, 255, 255, 0.7)',
                      }}
                    >
                      {message.timestamp}
                    </Typography>
                  </Paper>
                </Box>
              ))}
            </Stack>
          </Box>

          {/* Message Input */}
          <Box sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
            <Stack direction="row" spacing={1}>
              <TextField
                fullWidth
                size="small"
                placeholder="Type a message..."
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />
              <IconButton
                color="primary"
                onClick={handleSendMessage}
                disabled={!messageInput.trim()}
              >
                <SendIcon />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default MessagesPage;
