import React, { useState } from 'react';
import { isMobile as isMobileDevice, isSafari } from 'react-device-detect';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  styled,
  IconButton,
  Drawer,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Work as WorkIcon,
  Bookmark as BookmarkIcon,
  Description as DescriptionIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  Add as AddIcon,
  VideoCall as InterviewIcon,
  Email as MessageIcon,
  MailOutline as TemplatesIcon,
  Group as TeamIcon,
  Settings as SettingsIcon,
  AssignmentTurnedIn as AssessmentIcon,
  LibraryBooks as ResourcesIcon,
  Security as ClearanceIcon,
  Tune as PreferencesIcon,
  TrackChanges as ApplicationTrackingIcon,
  School as LearningIcon,
  Assessment as ReportsIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  Person as ProfileIcon,
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

const StyledSidebar = styled(Box)(({ theme }) => ({
  width: 250,
  backgroundColor: '#f5f5f5',
  minHeight: 'calc(100vh - 70px)',
  position: 'fixed',
  left: 0,
  top: '70px',
  borderRight: `1px solid ${theme.palette.divider}`,
  overflowY: 'auto',
  zIndex: theme.zIndex.drawer,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  ...(isMobileDevice && isSafari && {
    height: '-webkit-fill-available',
    minHeight: '-webkit-fill-available',
  })
}));

interface SidebarItem {
  icon: React.ReactNode;
  text: string;
  path: string;
}

interface SidebarProps {
  items: SidebarItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ items }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarContent = (
    <List>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton
            component={Link}
            to={item.path}
            selected={location.pathname === item.path}
            onClick={isMobile ? handleDrawerToggle : undefined}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
                '& .MuiListItemIcon-root': {
                  color: 'white',
                },
              },
              '&:hover': {
                backgroundColor: 'primary.light',
                color: 'white',
                '& .MuiListItemIcon-root': {
                  color: 'white',
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 40,
                color: location.pathname === item.path ? 'white' : 'inherit',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            position: 'fixed',
            left: 16,
            top: 16,
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {isMobile ? (
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: 250,
              marginTop: '70px',
            },
          }}
        >
          {sidebarContent}
        </Drawer>
      ) : (
        <StyledSidebar component="nav">
          {sidebarContent}
        </StyledSidebar>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${250}px)` },
          ml: { sm: `${250}px` },
        }}
      >
        {/* This is where your page content will go */}
      </Box>
    </>
  );
};

export const getCandidateSidebarItems = (): SidebarItem[] => [
  { icon: <DashboardIcon />, text: 'Dashboard', path: '/candidate/dashboard' },
  { icon: <WorkIcon />, text: 'Find Jobs', path: '/candidate/jobs' },
  { icon: <BookmarkIcon />, text: 'Saved Jobs', path: '/candidate/saved-jobs' },
  { icon: <ApplicationTrackingIcon />, text: 'Applications', path: '/candidate/applications' },
  { icon: <DescriptionIcon />, text: 'Documents', path: '/candidate/documents' },
  { icon: <AssessmentIcon />, text: 'Skill Assessments', path: '/candidate/assessments' },
  { icon: <MessageIcon />, text: 'Messages', path: '/candidate/messages' },
  { icon: <NotificationsIcon />, text: 'Notifications', path: '/candidate/notifications' },
  { icon: <LearningIcon />, text: 'Learning Path', path: '/candidate/learning-path' },
  { icon: <ResourcesIcon />, text: 'Career Resources', path: '/candidate/resources' },
  { icon: <ClearanceIcon />, text: 'Clearance Guide', path: '/candidate/clearance-guide' },
  { icon: <ReportsIcon />, text: 'Reports', path: '/candidate/reports' },
  { icon: <PreferencesIcon />, text: 'Preferences', path: '/candidate/preferences' },
  { icon: <ProfileIcon />, text: 'Profile', path: '/candidate/profile' },
];

export const getRecruiterSidebarItems = (): SidebarItem[] => [
  { icon: <DashboardIcon />, text: 'Dashboard', path: '/recruiter/dashboard' },
  { icon: <WorkIcon />, text: 'Job Listings', path: '/recruiter/jobs' },
  { icon: <AddIcon />, text: 'Post Job', path: '/recruiter/post-job' },
  { icon: <PeopleIcon />, text: 'Candidates', path: '/recruiter/candidates' },
  { icon: <InterviewIcon />, text: 'Interviews', path: '/recruiter/interviews' },
  { icon: <MessageIcon />, text: 'Messages', path: '/recruiter/messages' },
  { icon: <NotificationsIcon />, text: 'Notifications', path: '/recruiter/notifications' },
  { icon: <TemplatesIcon />, text: 'Email Templates', path: '/recruiter/email-templates' },
  { icon: <TeamIcon />, text: 'Team', path: '/recruiter/team' },
  { icon: <BusinessIcon />, text: 'Company', path: '/recruiter/company' },
  { icon: <AssessmentIcon />, text: 'Assessments', path: '/recruiter/assessments' },
  { icon: <DescriptionIcon />, text: 'Reports', path: '/recruiter/reports' },
  { icon: <SettingsIcon />, text: 'Settings', path: '/recruiter/settings' },
  { icon: <ProfileIcon />, text: 'Profile', path: '/recruiter/profile' },
];

export default Sidebar;
