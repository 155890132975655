import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Chip,
  IconButton,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  LinearProgress,
  useTheme,
  alpha,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import SchoolIcon from '@mui/icons-material/School';
import SecurityIcon from '@mui/icons-material/Security';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PageHeader from '../../components/common/PageHeader';
import ResponsiveProfileMenu from '../../components/common/ResponsiveProfileMenu';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const ProfilePage: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [skills, setSkills] = useState<string[]>(['JavaScript', 'React', 'TypeScript']);
  const [newSkill, setNewSkill] = useState('');
  const [clearanceLevel, setClearanceLevel] = useState('');
  const [industries, setIndustries] = useState<string[]>(['Defense', 'Technology', 'Healthcare']);
  const [newIndustry, setNewIndustry] = useState('');
  const theme = useTheme();

  const handleAddSkill = () => {
    if (newSkill && !skills.includes(newSkill)) {
      setSkills([...skills, newSkill]);
      setNewSkill('');
    }
  };

  const handleDeleteSkill = (skillToDelete: string) => {
    setSkills(skills.filter((skill) => skill !== skillToDelete));
  };

  const handleAddIndustry = () => {
    if (newIndustry && !industries.includes(newIndustry)) {
      setIndustries([...industries, newIndustry]);
      setNewIndustry('');
    }
  };

  const handleDeleteIndustry = (industryToDelete: string) => {
    setIndustries(industries.filter((industry) => industry !== industryToDelete));
  };

  const profileTabs = [
    { icon: <PersonIcon />, label: 'Personal Info', value: 0 },
    { icon: <WorkIcon />, label: 'Experience', value: 1 },
    { icon: <SchoolIcon />, label: 'Education', value: 2 },
    { icon: <SecurityIcon />, label: 'Security Clearance', value: 3 },
    { icon: <BusinessCenterIcon />, label: 'Industry Focus', value: 4 },
    { icon: <ArticleIcon />, label: 'Documents', value: 5 },
    { icon: <SettingsIcon />, label: 'Settings', value: 6 },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title="Profile"
        subtitle="Manage your personal information and preferences"
        stats={[
          { value: skills.length, label: 'Skills', color: 'primary' },
          { value: industries.length, label: 'Industries', color: 'success' },
          { value: '85%', label: 'Profile Completion', color: 'info' },
        ]}
      />

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <ResponsiveProfileMenu
            tabs={profileTabs}
            value={tabValue}
            onChange={setTabValue}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 0, height: '100%' }}>
            <Box sx={{ mt: { xs: 0, sm: 2 }, px: { xs: 2, sm: 3 }, pb: 3 }}>
              <TabPanel value={tabValue} index={0}>
                <Typography variant="h6" gutterBottom>Personal Information</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="First Name" variant="outlined" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="Last Name" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Email" variant="outlined" type="email" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Phone" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Professional Summary"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <Typography variant="h6" gutterBottom>Work Experience</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Current Position" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Company" variant="outlined" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="Start Date" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="End Date" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Job Description"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" startIcon={<AddIcon />}>
                      Add Experience
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <Typography variant="h6" gutterBottom>Education</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Degree" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Institution" variant="outlined" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="Start Date" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="End Date" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" startIcon={<AddIcon />}>
                      Add Education
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={3}>
                <Typography variant="h6" gutterBottom>Security Clearance</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Clearance Level</InputLabel>
                      <Select
                        value={clearanceLevel}
                        onChange={(e) => setClearanceLevel(e.target.value)}
                        label="Clearance Level"
                      >
                        <MenuItem value="none">None</MenuItem>
                        <MenuItem value="confidential">Confidential</MenuItem>
                        <MenuItem value="secret">Secret</MenuItem>
                        <MenuItem value="top_secret">Top Secret</MenuItem>
                        <MenuItem value="ts_sci">TS/SCI</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Clearance Details"
                      variant="outlined"
                      multiline
                      rows={4}
                      placeholder="Additional details about your security clearance..."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Investigation Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={4}>
                <Typography variant="h6" gutterBottom>Industry Focus</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>Industries</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {industries.map((industry) => (
                          <Chip
                            key={industry}
                            label={industry}
                            onDelete={() => handleDeleteIndustry(industry)}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <TextField
                        fullWidth
                        label="Add Industry"
                        variant="outlined"
                        value={newIndustry}
                        onChange={(e) => setNewIndustry(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        onClick={handleAddIndustry}
                        disabled={!newIndustry}
                      >
                        <AddIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>Skills</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {skills.map((skill) => (
                          <Chip
                            key={skill}
                            label={skill}
                            onDelete={() => handleDeleteSkill(skill)}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <TextField
                        fullWidth
                        label="Add Skill"
                        variant="outlined"
                        value={newSkill}
                        onChange={(e) => setNewSkill(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        onClick={handleAddSkill}
                        disabled={!newSkill}
                      >
                        <AddIcon />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={5}>
                <Typography variant="h6" gutterBottom>Documents</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="subtitle1" gutterBottom>Resume</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Button
                            variant="contained"
                            startIcon={<UploadIcon />}
                            component="label"
                          >
                            Upload Resume
                            <input type="file" hidden accept=".pdf,.doc,.docx" />
                          </Button>
                          <Typography variant="body2" color="text.secondary">
                            Supported formats: PDF, DOC, DOCX
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="subtitle1" gutterBottom>Certifications</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Button
                            variant="contained"
                            startIcon={<UploadIcon />}
                            component="label"
                          >
                            Upload Certificate
                            <input type="file" hidden accept=".pdf,.jpg,.png" />
                          </Button>
                          <Typography variant="body2" color="text.secondary">
                            Supported formats: PDF, JPG, PNG
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={6}>
                <Typography variant="h6" gutterBottom>Settings</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Job Search Status</InputLabel>
                      <Select
                        defaultValue="active"
                        label="Job Search Status"
                      >
                        <MenuItem value="active">Actively Looking</MenuItem>
                        <MenuItem value="passive">Open to Opportunities</MenuItem>
                        <MenuItem value="not_looking">Not Looking</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Preferred Work Location</InputLabel>
                      <Select
                        defaultValue="hybrid"
                        label="Preferred Work Location"
                      >
                        <MenuItem value="onsite">On-site</MenuItem>
                        <MenuItem value="hybrid">Hybrid</MenuItem>
                        <MenuItem value="remote">Remote</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Email Notifications</InputLabel>
                      <Select
                        defaultValue="all"
                        label="Email Notifications"
                      >
                        <MenuItem value="all">All Notifications</MenuItem>
                        <MenuItem value="important">Important Only</MenuItem>
                        <MenuItem value="none">None</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfilePage;
