import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  styled,
  Paper,
  AppBar,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface ResponsiveProfileMenuProps {
  tabs: {
    icon: React.ReactElement;
    label: string;
    value: number;
  }[];
  value: number;
  onChange: (newValue: number) => void;
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  '& .MuiTab-root': {
    minWidth: 160,
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const MobileAppBar = styled(AppBar)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: '100%',
    left: '0 !important',
    right: '0 !important',
    maxHeight: '80vh',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 320,
      left: 'auto !important',
    },
  },
  '& .MuiList-root': {
    padding: theme.spacing(1),
  },
  '& .MuiMenuItem-root': {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0.5),
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const ResponsiveProfileMenu: React.FC<ResponsiveProfileMenuProps> = ({
  tabs,
  value,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (newValue: number) => {
    onChange(newValue);
    handleClose();
  };

  const currentTab = tabs.find(tab => tab.value === value);

  return (
    <>
      <Paper elevation={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <StyledTabs
          orientation="vertical"
          value={value}
          onChange={(_, newValue) => onChange(newValue)}
          aria-label="Profile sections"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              icon={tab.icon}
              label={tab.label}
              value={tab.value}
              iconPosition="start"
            />
          ))}
        </StyledTabs>
      </Paper>

      {isMobile && (
        <>
          <MobileAppBar elevation={0}>
            <Toolbar>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
                {currentTab?.icon}
                <Typography variant="subtitle1">
                  {currentTab?.label}
                </Typography>
              </Box>
              <IconButton
                edge="end"
                onClick={handleClick}
                color="primary"
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </MobileAppBar>

          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {tabs.map((tab) => (
              <MenuItem
                key={tab.value}
                onClick={() => handleMenuItemClick(tab.value)}
                selected={value === tab.value}
                sx={{
                  py: 1.5,
                  px: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {React.cloneElement(tab.icon, {
                    sx: { color: value === tab.value ? 'inherit' : 'primary.main' },
                  })}
                  {tab.label}
                </Box>
              </MenuItem>
            ))}
          </StyledMenu>
        </>
      )}
    </>
  );
};

export default ResponsiveProfileMenu;
