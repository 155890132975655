import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  Button,
  Stack,
  Avatar,
  Tooltip,
  Link,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import SchoolIcon from '@mui/icons-material/School';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export interface JobCardProps {
  id: string;
  title: string;
  company: string;
  location: string;
  isOnSite?: boolean;
  updatedAt?: string;
  experience?: string;
  clearance: string;
  description: string;
  isNew?: boolean;
  recruiterName?: string;
  recruiterInitial?: string;
  recruiterColor?: string;
  salary?: string;
  clearanceLevel?: string;
  postDate?: string;
  certifications?: string[];
  experienceYears?: string;
  education?: string;
  jobType?: string;
  companyLogo?: string;
  companySize?: string;
}

const JobCard: React.FC<JobCardProps> = ({
  id,
  title,
  company,
  location,
  isOnSite,
  updatedAt,
  experience,
  clearance,
  description,
  isNew,
  recruiterName,
  recruiterInitial,
  recruiterColor,
  salary,
  clearanceLevel,
  postDate,
  certifications,
  experienceYears,
  education,
  jobType,
  companyLogo,
  companySize,
}) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s, box-shadow 0.2s',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 3,
        },
      }}
      onClick={() => navigate(`/jobs/${id}`)}
    >
      <CardContent>
        <Box display="flex" gap={2}>
          {companyLogo && (
            <Avatar
              src={companyLogo}
              alt={company}
              sx={{ width: 64, height: 64 }}
            />
          )}
          <Box flex={1}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>

            <Stack spacing={1.5}>
              <Box display="flex" alignItems="center" gap={1}>
                <BusinessIcon color="action" fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  {company} {companySize && `(${companySize} employees)`}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" gap={1}>
                <LocationOnIcon color="action" fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  {location} {isOnSite && "(ON/OFF SITE)"}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" gap={1}>
                <AttachMoneyIcon color="action" fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  {salary}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" gap={1}>
                <CalendarTodayIcon color="action" fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  Posted {postDate}
                </Typography>
              </Box>
            </Stack>

            <Box my={2}>
              <Chip
                icon={<SecurityIcon />}
                label={clearanceLevel}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
              />
              <Chip
                icon={<WorkIcon />}
                label={`${experienceYears} Experience`}
                sx={{ mr: 1, mb: 1 }}
              />
              <Chip
                icon={<SchoolIcon />}
                label={education}
                sx={{ mr: 1, mb: 1 }}
              />
            </Box>

            {certifications && certifications.length > 0 && (
              <Box mb={2}>
                {certifications.map((cert) => (
                  <Chip
                    key={cert}
                    label={cert}
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Box>
            )}

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                mb: 2,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {description}
            </Typography>

            <Stack direction="row" spacing={2} mt="auto">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();
                  // Handle apply action
                }}
              >
                APPLY NOW
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();
                  // Handle interest action
                }}
              >
                I'M INTERESTED
              </Button>
            </Stack>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default JobCard;
