import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Chip,
  Box,
  IconButton,
  Divider,
  useTheme,
  Stack,
  alpha,
} from '@mui/material';
import {
  LocationOn as LocationIcon,
  Business as BusinessIcon,
  Delete as DeleteIcon,
  Share as ShareIcon,
  Visibility as VisibilityIcon,
  Bookmark as BookmarkIcon,
  Security as SecurityIcon,
  Work as WorkIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/common/PageHeader';

// Sample data for saved jobs
const savedJobs = [
  {
    id: 1,
    title: 'Senior Software Engineer',
    company: 'Tech Solutions Inc.',
    location: 'Washington, DC',
    clearance: 'TS/SCI',
    salary: '$120,000 - $160,000',
    type: 'Full-time',
    savedDate: '2024-01-05',
    description: 'Seeking an experienced software engineer with strong background in cloud computing and security clearance...',
    skills: ['Java', 'AWS', 'Kubernetes', 'Security+'],
    matchScore: 95,
    deadline: '2024-02-15'
  },
  {
    id: 2,
    title: 'Systems Analyst',
    company: 'Defense Dynamics',
    location: 'Arlington, VA',
    clearance: 'Secret',
    salary: '$90,000 - $120,000',
    type: 'Full-time',
    savedDate: '2024-01-03',
    description: 'Looking for a systems analyst to support our defense contracts...',
    skills: ['System Analysis', 'Python', 'SQL', 'CISSP'],
    matchScore: 85,
    deadline: '2024-02-28'
  },
  {
    id: 3,
    title: 'Project Manager',
    company: 'Federal Systems LLC',
    location: 'Alexandria, VA',
    clearance: 'Top Secret',
    salary: '$110,000 - $140,000',
    type: 'Contract',
    savedDate: '2024-01-02',
    description: 'Experienced project manager needed for large-scale government IT projects...',
    skills: ['PMP', 'Agile', 'Government Contracting', 'Risk Management'],
    matchScore: 75,
    deadline: '2024-03-15'
  },
  {
    id: 4,
    title: 'DevOps Engineer',
    company: 'Cloud Security Corp',
    location: 'Remote',
    clearance: 'TS/SCI',
    salary: '$130,000 - $170,000',
    type: 'Full-time',
    savedDate: '2024-01-01',
    description: 'Join our team as a DevOps engineer to support classified cloud infrastructure...',
    skills: ['DevOps', 'CI/CD', 'Docker', 'Azure'],
    matchScore: 90,
    deadline: '2024-03-20'
  },
];

const SavedJobsPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Calculate stats from saved jobs
  const stats = [
    {
      label: 'Saved Jobs',
      count: savedJobs.length,
      color: theme.palette.info.main, // Blue color for bookmarks/saved items
      icon: <BookmarkIcon sx={{ fontSize: 24 }} />,
    },
    {
      label: 'TS/SCI Positions',
      count: savedJobs.filter(job => job.clearance === 'TS/SCI').length,
      color: '#9c27b0', // Purple for high security clearance
      icon: <SecurityIcon sx={{ fontSize: 24 }} />,
    },
    {
      label: 'Full-time Roles',
      count: savedJobs.filter(job => job.type === 'Full-time').length,
      color: '#2e7d32', // Green for permanent positions
      icon: <WorkIcon sx={{ fontSize: 24 }} />,
    },
  ];

  const handleDelete = (id: number) => {
    // Implement delete logic
    console.log('Delete clicked for id:', id);
  };

  const handleShare = (id: number) => {
    // Implement share logic
    console.log('Share clicked for id:', id);
  };

  const handleViewJob = (id: number) => {
    navigate(`/candidate/jobs/${id}`);
  };

  const handleClearAll = () => {
    // Implement clear all logic
    console.log('Clear all clicked');
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader
        title="Saved Jobs"
        subtitle="Review and manage your saved job listings"
        stats={[
          {
            value: savedJobs.length,
            label: 'Saved Jobs',
            color: 'primary'
          },
          {
            value: savedJobs.filter(job => job.matchScore >= 80).length,
            label: 'High Matches',
            color: 'success'
          },
          {
            value: savedJobs.filter(job => job.deadline && new Date(job.deadline) > new Date()).length,
            label: 'Closing Soon',
            color: 'warning'
          }
        ]}
        action={
          <Button
            variant="outlined"
            onClick={handleClearAll}
            startIcon={<DeleteIcon />}
          >
            Clear All
          </Button>
        }
      />
      <Stack spacing={3}>
        {savedJobs.map((job) => (
          <Card key={job.id} sx={{
            backgroundColor: '#fff',
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
          }}>
            <CardContent>
              <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                  <Stack spacing={2}>
                    <Typography variant="h6" component="h2" sx={{
                      color: '#1a237e',
                      fontWeight: 600,
                    }}>
                      {job.title}
                    </Typography>
                    <Stack spacing={1}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <BusinessIcon sx={{ color: 'text.secondary' }} />
                        <Typography component="span" variant="body2" color="text.secondary">
                          {job.company}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <LocationIcon sx={{ color: 'text.secondary' }} />
                        <Typography component="span" variant="body2" color="text.secondary">
                          {job.location}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Chip 
                      label={job.clearance} 
                      sx={{ 
                        backgroundColor: '#e8eaf6',
                        color: '#1a237e',
                        fontWeight: 500,
                      }} 
                    />
                    <Chip 
                      label={job.type} 
                      variant="outlined" 
                      sx={{ 
                        borderColor: alpha(theme.palette.primary.main, 0.3),
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                      }}
                    />
                  </Stack>
                </Stack>

                <Typography variant="body2" component="p" color="text.secondary">
                  {job.description}
                </Typography>

                <Stack spacing={1}>
                  <Typography variant="subtitle2" component="h3">
                    Required Skills:
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {job.skills.map((skill, index) => (
                      <Chip
                        key={index}
                        label={skill}
                        size="small"
                        variant="outlined"
                        sx={{
                          borderColor: alpha(theme.palette.primary.main, 0.3),
                          color: theme.palette.primary.main,
                        }}
                      />
                    ))}
                  </Stack>
                </Stack>

                <Divider />

                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography component="span" variant="body2" color="text.secondary">
                    Salary Range: {job.salary}
                  </Typography>
                  <Typography component="span" variant="body2" color="text.secondary">
                    Saved on: {job.savedDate}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
              <IconButton
                onClick={() => handleDelete(job.id)}
                sx={{ color: theme.palette.error.main }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => handleShare(job.id)}
                sx={{ color: theme.palette.primary.main }}
              >
                <ShareIcon />
              </IconButton>
              <Button
                variant="contained"
                startIcon={<VisibilityIcon />}
                onClick={() => handleViewJob(job.id)}
                sx={{
                  ml: 1,
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                View Job
              </Button>
            </CardActions>
          </Card>
        ))}
      </Stack>
    </Container>
  );
};

export default SavedJobsPage;
