import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  Stack,
  Chip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FactoryIcon from '@mui/icons-material/Factory';
import BuildIcon from '@mui/icons-material/Build';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import JobSearch from '../components/JobSearch';
import Filters from '../components/Filters';
import JobList from '../components/JobList';
import Logo from '../components/common/Logo';
import { Job, SearchParams, FilterParams } from '../types';
import { Link } from 'react-router-dom';
import { Work, Business } from '@mui/icons-material';
import logoImage from '../assets/SecureRecruit_Logo.jpeg';

const sampleJobs: Job[] = [
  {
    id: 1,
    title: 'Software Engineer',
    company: 'ABC Corp',
    location: 'Washington, D.C.',
    description: 'Develop and maintain software applications...',
    jobType: 'Full-Time',
    experience: 'Mid',
    salary: '$120,000 - $180,000',
    clearanceLevel: 'Top Secret/SCI',
    requiredSkills: ['Java', 'Spring Boot', 'AWS', 'Microservices'],
    benefits: ['Health Insurance', '401k', 'Remote Work Options'],
    postedDate: '2024-12-01',
  },
  {
    id: 2,
    title: 'Security Analyst',
    company: 'XYZ Inc',
    location: 'Ashburn, VA',
    description: 'Monitor and analyze security threats...',
    jobType: 'Full-Time',
    experience: 'Entry',
    salary: '$80,000 - $120,000',
    clearanceLevel: 'Secret',
    requiredSkills: ['Security+', 'Network Security', 'Incident Response'],
    benefits: ['Health Insurance', 'Life Insurance', 'Training Budget'],
    postedDate: '2024-12-05',
  },
];

const features = [
  {
    icon: <WorkIcon fontSize="large" color="primary" />,
    title: 'All Industries Welcome',
    description: 'From tech to healthcare, finance to manufacturing - find opportunities across every sector',
  },
  {
    icon: <SecurityIcon fontSize="large" color="primary" />,
    title: 'Security Cleared Positions',
    description: 'Specialized opportunities for cleared professionals in defense and government sectors',
  },
  {
    icon: <SchoolIcon fontSize="large" color="primary" />,
    title: 'Career Development',
    description: 'Entry-level to executive positions with competitive benefits and growth potential',
  },
];

const popularSearches = [
  'Software Engineer',
  'Healthcare Administrator',
  'Financial Analyst',
  'Project Manager',
  'Data Scientist',
  'Manufacturing Engineer',
  'Sales Executive',
  'Cyber Security',
];

const industryCategories = [
  {
    icon: <EngineeringIcon fontSize="large" color="primary" />,
    title: 'Technology',
    roles: ['Software Engineer', 'Data Scientist', 'Cloud Architect', 'DevOps Engineer'],
  },
  {
    icon: <SecurityIcon fontSize="large" color="primary" />,
    title: 'Defense & Security',
    roles: ['Security Analyst', 'Intelligence Officer', 'Program Manager', 'Systems Engineer'],
  },
  {
    icon: <HealthAndSafetyIcon fontSize="large" color="primary" />,
    title: 'Healthcare',
    roles: ['Medical Director', 'Clinical Research', 'Healthcare Admin', 'Biotech Researcher'],
  },
  {
    icon: <AccountBalanceIcon fontSize="large" color="primary" />,
    title: 'Finance',
    roles: ['Financial Analyst', 'Risk Manager', 'Investment Banker', 'Compliance Officer'],
  },
  {
    icon: <FactoryIcon fontSize="large" color="primary" />,
    title: 'Manufacturing',
    roles: ['Production Manager', 'Quality Engineer', 'Process Engineer', 'Operations Director'],
  },
  {
    icon: <LocalShippingIcon fontSize="large" color="primary" />,
    title: 'Logistics',
    roles: ['Supply Chain Manager', 'Logistics Coordinator', 'Fleet Manager', 'Distribution Director'],
  },
];

const benefits = [
  {
    icon: <BuildIcon fontSize="large" color="primary" />,
    title: 'Powerful Job Search Tools',
    description: 'Advanced filters and AI-powered matching to find your perfect role',
  },
  {
    icon: <SecurityIcon fontSize="large" color="primary" />,
    title: 'Security Clearance Expertise',
    description: 'Specialized filtering for clearance levels and secure positions',
  },
  {
    icon: <WorkIcon fontSize="large" color="primary" />,
    title: 'Career Resources',
    description: 'Resume builder, salary insights, and interview preparation tools',
  },
];

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showJobSearch, setShowJobSearch] = useState(false);
  const [jobs] = useState<Job[]>(sampleJobs);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>(sampleJobs);

  const handleSearch = ({ keyword, location }: SearchParams) => {
    navigate('/candidate/jobs', { state: { searchParams: { keyword, location } } });
  };

  const handleFilter = (filters: FilterParams) => {
    let filtered = jobs;
    
    if (filters.jobTitle) {
      filtered = filtered.filter((job) =>
        job.title.toLowerCase().includes(filters.jobTitle!.toLowerCase())
      );
    }

    if (filters.jobType && filters.jobType.length > 0) {
      filtered = filtered.filter((job) =>
        filters.jobType!.includes(job.jobType)
      );
    }

    if (filters.experience && filters.experience.length > 0) {
      filtered = filtered.filter((job) =>
        filters.experience!.includes(job.experience)
      );
    }

    if (filters.clearanceLevel && filters.clearanceLevel.length > 0) {
      filtered = filtered.filter((job) =>
        filters.clearanceLevel!.includes(job.clearanceLevel)
      );
    }

    if (filters.salaryRange && filters.salaryRange.length > 0) {
      filtered = filtered.filter((job) => {
        const jobLowerBound = parseInt(job.salary.split(' - ')[0].replace(/[$,]/g, ''));
        return filters.salaryRange!.some(range => {
          const rangeLowerBound = parseInt(range.split(' - ')[0].replace(/[$,]/g, ''));
          return jobLowerBound >= rangeLowerBound;
        });
      });
    }

    if (filters.location && filters.location.length > 0) {
      filtered = filtered.filter((job) =>
        filters.location!.some(loc => 
          job.location.toLowerCase().includes(loc.toLowerCase())
        )
      );
    }

    setFilteredJobs(filtered);
  };

  return (
    <Stack>
      {/* Header with Logo */}
      <Stack sx={{ bgcolor: 'background.paper', py: 2 }}>
        <Container maxWidth="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <img src={logoImage} alt="Logo" style={{ height: '50px' }} />
          </Stack>
        </Container>
      </Stack>

      {/* Hero Section */}
      <Stack sx={{ bgcolor: 'primary.main', color: 'white', py: 8 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Typography variant="h1" component="h1">
                  AIRecruit Pros
                </Typography>
                <Typography variant="h4" component="div">
                  Connecting Top Talent Across Industries
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Technology • Defense • Healthcare • Commercial
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <JobSearch onSearch={handleSearch} />
            </Grid>
          </Grid>
        </Container>
      </Stack>

      {/* Dashboard Links Section */}
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 4, 
                textAlign: 'center',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                }
              }}
            >
              <Work sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
              <Typography variant="h5" gutterBottom>
                Candidate Dashboard
              </Typography>
              <Typography color="textSecondary" paragraph>
                Search jobs, manage your profile, and track your applications
              </Typography>
              <Button
                component={Link}
                to="/candidate/dashboard"
                variant="contained"
                size="large"
                sx={{ mt: 2 }}
              >
                Access Candidate Portal
              </Button>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 4, 
                textAlign: 'center',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                }
              }}
            >
              <Business sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
              <Typography variant="h5" gutterBottom>
                Recruiter Dashboard
              </Typography>
              <Typography color="textSecondary" paragraph>
                Post jobs, manage candidates, and grow your team
              </Typography>
              <Button
                component={Link}
                to="/recruiter/dashboard"
                variant="contained"
                size="large"
                sx={{ mt: 2 }}
              >
                Access Recruiter Portal
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {/* Popular Searches */}
      <Container maxWidth="lg" sx={{ mt: 10, mb: 8 }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h6" color="text.secondary">
            Popular Searches
          </Typography>
        </Box>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {popularSearches.map((search) => (
            <Chip
              key={search}
              label={search}
              onClick={() => handleSearch({ keyword: search, location: '' })}
              sx={{ m: 0.5 }}
            />
          ))}
        </Stack>
      </Container>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ mb: 8 }}>
        <Grid container spacing={4}>
          {features.map((feature) => (
            <Grid item xs={12} md={4} key={feature.title}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {feature.icon}
                <Typography variant="h6" sx={{ my: 2 }}>
                  {feature.title}
                </Typography>
                <Typography color="text.secondary">
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Industry Categories */}
      <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ textAlign: 'center', mb: 6 }}
          >
            Browse by Industry
          </Typography>
          <Grid container spacing={4}>
            {industryCategories.map((category) => (
              <Grid item xs={12} sm={6} md={4} key={category.title}>
                <Paper
                  sx={{
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {category.icon}
                    <Typography variant="h6" sx={{ ml: 1 }}>
                      {category.title}
                    </Typography>
                  </Box>
                  {category.roles.map((role) => (
                    <Button
                      key={role}
                      sx={{ justifyContent: 'flex-start', mb: 1 }}
                      onClick={() => handleSearch({ keyword: role, location: '' })}
                    >
                      {role}
                    </Button>
                  ))}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Job Search Section */}
      {showJobSearch && (
        <Box>
          <Container maxWidth="lg" sx={{ py: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Logo color="primary" size="small" onClick={() => setShowJobSearch(false)} />
            <Button
              variant="outlined"
              onClick={() => setShowJobSearch(false)}
              startIcon={<ArrowBackIcon />}
            >
              Back to Home
            </Button>
          </Container>
          <Container maxWidth="lg" sx={{ py: 4 }}>
            <Filters onFilter={handleFilter} />
            <JobList jobs={filteredJobs} />
          </Container>
        </Box>
      )}
    </Stack>
  );
};

export default HomePage;
