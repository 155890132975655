import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  Button, 
  Grid, 
  Chip, 
  Box,
  Skeleton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

interface JobDetail {
  title: string;
  company: string;
  location: string;
  clearanceLevel: string;
  jobType: string;
  salary: string;
  description: string;
  requirements: string[];
}

const JobDetailPage: React.FC = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState<JobDetail | null>(null);

  useEffect(() => {
    // Simulated API call to fetch job details
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        // TODO: Replace with actual API call
        // Simulated data for now
        const mockJob: JobDetail = {
          title: 'Senior Software Engineer',
          company: 'Tech Solutions Inc.',
          location: 'Washington, DC',
          clearanceLevel: 'TS/SCI',
          jobType: 'Full Time',
          salary: '$120,000 - $180,000',
          description: 'We are seeking an experienced Software Engineer to join our team...',
          requirements: [
            'Bachelor\'s degree in Computer Science or related field',
            '5+ years of experience in software development',
            'Active TS/SCI clearance required',
            'Experience with React, TypeScript, and Node.js',
          ],
        };
        
        await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate network delay
        setJob(mockJob);
      } catch (error) {
        console.error('Error fetching job details:', error);
        // Handle error appropriately
      } finally {
        setLoading(false);
      }
    };

    if (jobId) {
      fetchJobDetails();
    }
  }, [jobId]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Skeleton variant="text" height={60} />
          <Skeleton variant="text" height={30} sx={{ mb: 2 }} />
          <Box sx={{ my: 2 }}>
            <Skeleton variant="rectangular" width={100} height={32} sx={{ mr: 1, display: 'inline-block' }} />
            <Skeleton variant="rectangular" width={100} height={32} sx={{ display: 'inline-block' }} />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={100} />
              <Skeleton variant="text" height={100} />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  if (!job) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" color="error">
            Job not found
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => navigate('/jobs')}
            sx={{ mt: 2 }}
          >
            Back to Jobs
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {job.title}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          {job.company} • {job.location}
        </Typography>
        <Box sx={{ my: 2 }}>
          <Chip 
            icon={<SecurityIcon />}
            label={`${job.clearanceLevel} Required`}
            color="primary" 
            sx={{ mr: 1 }} 
          />
          <Chip 
            icon={<WorkIcon />}
            label={job.jobType}
            sx={{ mr: 1 }} 
          />
        </Box>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Location" secondary={job.location} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Salary Range" secondary={job.salary} />
              </ListItem>
            </List>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="h6" gutterBottom>
              Job Description
            </Typography>
            <Typography paragraph>
              {job.description}
            </Typography>
            
            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Requirements
            </Typography>
            <List>
              {job.requirements.map((req, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`• ${req}`} />
                </ListItem>
              ))}
            </List>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Box sx={{ position: 'sticky', top: 20 }}>
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth 
                size="large"
                sx={{ mb: 2 }}
              >
                Apply Now
              </Button>
              <Button 
                variant="outlined" 
                fullWidth
                size="large"
              >
                Save Job
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default JobDetailPage;
