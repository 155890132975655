import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Chip,
  IconButton,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Card,
  CardContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PageHeader from '../../components/common/PageHeader';
import ResponsiveProfileMenu from '../../components/common/ResponsiveProfileMenu';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const RecruiterProfilePage: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [industries, setIndustries] = useState<string[]>(['Defense', 'Technology', 'Healthcare', 'Finance']);
  const [newIndustry, setNewIndustry] = useState('');
  const [clearanceLevel, setClearanceLevel] = useState('');
  const [specialties, setSpecialties] = useState<string[]>(['Technical Recruiting', 'Executive Search']);
  const [newSpecialty, setNewSpecialty] = useState('');

  const handleAddIndustry = () => {
    if (newIndustry && !industries.includes(newIndustry)) {
      setIndustries([...industries, newIndustry]);
      setNewIndustry('');
    }
  };

  const handleDeleteIndustry = (industryToDelete: string) => {
    setIndustries(industries.filter((industry) => industry !== industryToDelete));
  };

  const handleAddSpecialty = () => {
    if (newSpecialty && !specialties.includes(newSpecialty)) {
      setSpecialties([...specialties, newSpecialty]);
      setNewSpecialty('');
    }
  };

  const handleDeleteSpecialty = (specialtyToDelete: string) => {
    setSpecialties(specialties.filter((specialty) => specialty !== specialtyToDelete));
  };

  const profileTabs = [
    { icon: <PersonIcon />, label: 'Personal Info', value: 0 },
    { icon: <BusinessIcon />, label: 'Company Info', value: 1 },
    { icon: <BusinessCenterIcon />, label: 'Industry Focus', value: 2 },
    { icon: <WorkIcon />, label: 'Recruiting Specialties', value: 3 },
    { icon: <SecurityIcon />, label: 'Security Clearance', value: 4 },
    { icon: <GroupIcon />, label: 'Team', value: 5 },
    { icon: <SettingsIcon />, label: 'Settings', value: 6 },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title="Recruiter Profile"
        subtitle="Manage your professional information and preferences"
        stats={[
          { value: industries.length, label: 'Industries', color: 'primary' },
          { value: specialties.length, label: 'Specialties', color: 'success' },
          { value: '90%', label: 'Profile Completion', color: 'info' },
        ]}
      />

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <ResponsiveProfileMenu
            tabs={profileTabs}
            value={tabValue}
            onChange={setTabValue}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 0, height: '100%' }}>
            <Box sx={{ mt: { xs: 0, sm: 2 }, px: { xs: 2, sm: 3 }, pb: 3 }}>
              <TabPanel value={tabValue} index={0}>
                <Typography variant="h6" gutterBottom>Personal Information</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="First Name" variant="outlined" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="Last Name" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Professional Title" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Email" variant="outlined" type="email" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Phone" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Professional Summary"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <Typography variant="h6" gutterBottom>Company Information</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Company Name" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Company Website" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Company Description"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Office Location" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Company Size</InputLabel>
                      <Select
                        defaultValue="medium"
                        label="Company Size"
                      >
                        <MenuItem value="small">1-50 employees</MenuItem>
                        <MenuItem value="medium">51-200 employees</MenuItem>
                        <MenuItem value="large">201-1000 employees</MenuItem>
                        <MenuItem value="enterprise">1000+ employees</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <Typography variant="h6" gutterBottom>Industry Focus</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>Industries</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {industries.map((industry) => (
                          <Chip
                            key={industry}
                            label={industry}
                            onDelete={() => handleDeleteIndustry(industry)}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <TextField
                        fullWidth
                        label="Add Industry"
                        variant="outlined"
                        value={newIndustry}
                        onChange={(e) => setNewIndustry(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        onClick={handleAddIndustry}
                        disabled={!newIndustry}
                      >
                        <AddIcon />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={3}>
                <Typography variant="h6" gutterBottom>Recruiting Specialties</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>Specialties</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {specialties.map((specialty) => (
                          <Chip
                            key={specialty}
                            label={specialty}
                            onDelete={() => handleDeleteSpecialty(specialty)}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <TextField
                        fullWidth
                        label="Add Specialty"
                        variant="outlined"
                        value={newSpecialty}
                        onChange={(e) => setNewSpecialty(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        onClick={handleAddSpecialty}
                        disabled={!newSpecialty}
                      >
                        <AddIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Primary Focus Area</InputLabel>
                      <Select
                        defaultValue="technical"
                        label="Primary Focus Area"
                      >
                        <MenuItem value="technical">Technical Roles</MenuItem>
                        <MenuItem value="executive">Executive Search</MenuItem>
                        <MenuItem value="operations">Operations</MenuItem>
                        <MenuItem value="sales">Sales & Marketing</MenuItem>
                        <MenuItem value="general">General Staffing</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={4}>
                <Typography variant="h6" gutterBottom>Security Clearance</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Required Clearance Level</InputLabel>
                      <Select
                        value={clearanceLevel}
                        onChange={(e) => setClearanceLevel(e.target.value)}
                        label="Required Clearance Level"
                      >
                        <MenuItem value="none">None Required</MenuItem>
                        <MenuItem value="confidential">Confidential</MenuItem>
                        <MenuItem value="secret">Secret</MenuItem>
                        <MenuItem value="top_secret">Top Secret</MenuItem>
                        <MenuItem value="ts_sci">TS/SCI</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Clearance Details"
                      variant="outlined"
                      multiline
                      rows={4}
                      placeholder="Additional details about security clearance requirements..."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>FSO Status</InputLabel>
                      <Select
                        defaultValue="active"
                        label="FSO Status"
                      >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={5}>
                <Typography variant="h6" gutterBottom>Team Management</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="subtitle1" gutterBottom>Team Members</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField fullWidth label="Member Name" variant="outlined" />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField fullWidth label="Role" variant="outlined" />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField fullWidth label="Email" variant="outlined" type="email" />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon />}
                            >
                              Add Team Member
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={6}>
                <Typography variant="h6" gutterBottom>Settings</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Notification Preferences</InputLabel>
                      <Select
                        defaultValue="all"
                        label="Notification Preferences"
                      >
                        <MenuItem value="all">All Notifications</MenuItem>
                        <MenuItem value="important">Important Only</MenuItem>
                        <MenuItem value="none">None</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Job Posting Visibility</InputLabel>
                      <Select
                        defaultValue="public"
                        label="Job Posting Visibility"
                      >
                        <MenuItem value="public">Public</MenuItem>
                        <MenuItem value="private">Private</MenuItem>
                        <MenuItem value="network">Network Only</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Candidate Contact Preferences</InputLabel>
                      <Select
                        defaultValue="direct"
                        label="Candidate Contact Preferences"
                      >
                        <MenuItem value="direct">Direct Contact</MenuItem>
                        <MenuItem value="platform">Platform Messages Only</MenuItem>
                        <MenuItem value="email">Email Only</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RecruiterProfilePage;
