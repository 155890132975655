import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  IconButton,
  Collapse,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export interface FilterParams {
  jobTitle?: string;
  jobType?: string[];
  experience?: string[];
  clearanceLevel?: string[];
  polygraphType?: string[];
  salaryRange?: string[];
  location?: string[];
  workplaceType?: string[];
  jobCategory?: string[];
  citizenship?: string[];
  isRemote?: boolean;
  isUrgent?: boolean;
}

interface FiltersProps {
  onFilter: (filters: FilterParams) => void;
}

const jobCategories = [
  'Software Development',
  'Systems Engineering',
  'Network Engineering',
  'Cybersecurity',
  'Data Science',
  'Intelligence Analysis',
  'Program Management',
  'Systems Administration',
  'Cloud Engineering',
  'DevOps Engineering',
  'Information Security',
  'Database Administration',
  'Technical Writing',
  'Quality Assurance',
  'IT Support',
];

const clearanceLevels = [
  'Secret',
  'Top Secret',
  'TS/SCI',
  'TS/SCI with CI Poly',
  'TS/SCI with Full Scope Poly',
  'DOE Q Clearance',
  'DOE L Clearance',
  'Public Trust',
  'Confidential',
];

const polygraphTypes = [
  'Counter Intelligence Polygraph',
  'Full Scope/Lifestyle Polygraph',
  'No Polygraph Required',
];

const workplaceTypes = [
  'On-Site',
  'Remote',
  'Hybrid',
  'Flexible',
];

const experienceLevels = [
  'Entry Level (0-2 years)',
  'Mid Level (3-5 years)',
  'Senior Level (5-8 years)',
  'Lead/Principal (8-12 years)',
  'Architect (12+ years)',
  'Management',
  'Executive',
];

const jobTypes = [
  'Full-Time',
  'Part-Time',
  'Contract - Corp to Corp',
  'Contract - W2',
  'Contract to Hire',
  'Temporary',
  'Internship',
];

const locations = [
  'Washington, DC',
  'Arlington, VA',
  'Alexandria, VA',
  'Reston, VA',
  'McLean, VA',
  'Bethesda, MD',
  'Fort Meade, MD',
  'Remote',
];

const salaryRanges = [
  '$50,000 - $75,000',
  '$75,000 - $100,000',
  '$100,000 - $125,000',
  '$125,000 - $150,000',
  '$150,000 - $175,000',
  '$175,000 - $200,000',
  '$200,000+',
];

const Filters: React.FC<FiltersProps> = ({ onFilter }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<FilterParams>({
    jobTitle: '',
    jobType: [],
    experience: [],
    clearanceLevel: [],
    polygraphType: [],
    salaryRange: [],
    location: [],
    workplaceType: [],
    jobCategory: [],
    citizenship: [],
    isRemote: false,
    isUrgent: false,
  });

  const handleFilterChange = (filterType: keyof FilterParams, value: any) => {
    const newFilters = {
      ...selectedFilters,
      [filterType]: typeof value === 'string' && !Array.isArray(selectedFilters[filterType])
        ? value
        : Array.isArray(selectedFilters[filterType])
          ? (selectedFilters[filterType] as string[]).includes(value)
            ? (selectedFilters[filterType] as string[]).filter(item => item !== value)
            : [...(selectedFilters[filterType] as string[]), value]
          : [value]
    };
    setSelectedFilters(newFilters);
    onFilter(newFilters);
  };

  const handleCheckboxChange = (filterType: keyof FilterParams) => {
    const newFilters = {
      ...selectedFilters,
      [filterType]: !selectedFilters[filterType],
    };
    setSelectedFilters(newFilters);
    onFilter(newFilters);
  };

  return (
    <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Filters
        </Typography>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      {/* Quick Search Section - Always Visible */}
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="Search Job Titles"
          value={selectedFilters.jobTitle}
          onChange={(e) => handleFilterChange('jobTitle', e.target.value)}
          sx={{ mb: 2 }}
        />
        
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Salary Range</InputLabel>
          <Select
            multiple
            value={selectedFilters.salaryRange || []}
            onChange={(e) => handleFilterChange('salaryRange', e.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {salaryRanges.map((range) => (
              <MenuItem key={range} value={range}>
                {range}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Active Filters Display */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
        {Object.entries(selectedFilters).map(([key, value]) => {
          if (Array.isArray(value) && value.length > 0) {
            return value.map((item) => (
              <Chip
                key={`${key}-${item}`}
                label={`${key.replace(/([A-Z])/g, ' $1').trim()}: ${item}`}
                onDelete={() => handleFilterChange(key as keyof FilterParams, item)}
                sx={{ m: 0.5 }}
              />
            ));
          }
          return null;
        })}
      </Box>

      <Collapse in={expanded}>
        <Grid container spacing={3}>
          {/* Job Category Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Job Category
            </Typography>
            <FormGroup>
              {jobCategories.map((category) => (
                <FormControlLabel
                  key={category}
                  control={
                    <Checkbox
                      checked={selectedFilters.jobCategory?.includes(category)}
                      onChange={() => handleFilterChange('jobCategory', category)}
                    />
                  }
                  label={category}
                />
              ))}
            </FormGroup>
          </Grid>

          {/* Security Requirements Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Security Requirements
            </Typography>
            <FormGroup>
              {clearanceLevels.map((level) => (
                <FormControlLabel
                  key={level}
                  control={
                    <Checkbox
                      checked={selectedFilters.clearanceLevel?.includes(level)}
                      onChange={() => handleFilterChange('clearanceLevel', level)}
                    />
                  }
                  label={level}
                />
              ))}
              <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                Polygraph
              </Typography>
              {polygraphTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={selectedFilters.polygraphType?.includes(type)}
                      onChange={() => handleFilterChange('polygraphType', type)}
                    />
                  }
                  label={type}
                />
              ))}
            </FormGroup>
          </Grid>

          {/* Experience and Employment Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Experience Level
            </Typography>
            <FormGroup>
              {experienceLevels.map((level) => (
                <FormControlLabel
                  key={level}
                  control={
                    <Checkbox
                      checked={selectedFilters.experience?.includes(level)}
                      onChange={() => handleFilterChange('experience', level)}
                    />
                  }
                  label={level}
                />
              ))}
            </FormGroup>
          </Grid>

          {/* Job Type Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Employment Type
            </Typography>
            <FormGroup>
              {jobTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={selectedFilters.jobType?.includes(type)}
                      onChange={() => handleFilterChange('jobType', type)}
                    />
                  }
                  label={type}
                />
              ))}
            </FormGroup>
          </Grid>

          {/* Location Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Location
            </Typography>
            <FormGroup>
              {locations.map((location) => (
                <FormControlLabel
                  key={location}
                  control={
                    <Checkbox
                      checked={selectedFilters.location?.includes(location)}
                      onChange={() => handleFilterChange('location', location)}
                    />
                  }
                  label={location}
                />
              ))}
            </FormGroup>
          </Grid>

          {/* Work Environment Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Work Environment
            </Typography>
            <FormGroup>
              {workplaceTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={selectedFilters.workplaceType?.includes(type)}
                      onChange={() => handleFilterChange('workplaceType', type)}
                    />
                  }
                  label={type}
                />
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters.isRemote}
                    onChange={() => handleCheckboxChange('isRemote')}
                  />
                }
                label="Remote Work Available"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters.isUrgent}
                    onChange={() => handleCheckboxChange('isUrgent')}
                  />
                }
                label="Urgent Hiring"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
};

export default Filters;