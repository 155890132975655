import React from 'react';
import { Container, Typography, Grid, TextField, Button } from '@mui/material';

const CandidateSearchPage: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Search Candidates
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Keywords"
            variant="outlined"
            placeholder="Skills, job titles, etc."
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Clearance Level"
            variant="outlined"
            select
            SelectProps={{ native: true }}
          >
            <option value="">Any Clearance Level</option>
            <option value="Secret">Secret</option>
            <option value="Top Secret">Top Secret</option>
            <option value="TS/SCI">TS/SCI</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: '56px' }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      {/* Add candidates search results here */}
    </Container>
  );
};

export default CandidateSearchPage;
