import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import PageHeader from '../../components/common/PageHeader';

const applicationData = [
  { month: 'Jan', applications: 5 },
  { month: 'Feb', applications: 8 },
  { month: 'Mar', applications: 12 },
  { month: 'Apr', applications: 7 },
  { month: 'May', applications: 10 },
  { month: 'Jun', applications: 15 },
];

const skillProgressData = [
  { name: 'Technical Skills', value: 75 },
  { name: 'Soft Skills', value: 85 },
  { name: 'Security Knowledge', value: 60 },
  { name: 'Industry Knowledge', value: 70 },
];

const jobMatchData = [
  { name: 'High Match', value: 12 },
  { name: 'Medium Match', value: 18 },
  { name: 'Low Match', value: 8 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const ReportsPage: React.FC = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <PageHeader
        title="Reports & Analytics"
        subtitle="Track your job search progress and performance metrics"
        stats={[
          {
            value: applicationData.length,
            label: 'Applications',
            color: 'primary'
          },
          {
            value: `${skillProgressData.reduce((acc, item) => acc + item.value, 0) / skillProgressData.length}%`,
            label: 'Avg. Skill Score',
            color: 'success'
          }
        ]}
      />

      <Paper sx={{ mb: 3, borderRadius: '12px' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-indicator': {
              height: 3,
              borderRadius: '3px',
            },
            '& .MuiTab-root': {
              minWidth: 120,
              fontSize: '0.9rem',
              textTransform: 'none',
              fontWeight: 500,
              py: 2,
              '&.Mui-selected': {
                fontWeight: 600,
              },
            },
          }}
        >
          <Tab label="Overview" />
          <Tab label="Applications" />
          <Tab label="Skills" />
          <Tab label="Job Matches" />
        </Tabs>
      </Paper>

      {/* Overview Tab */}
      {tabValue === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Application Activity
              </Typography>
              <Box sx={{ width: '100%', height: { xs: '300px', md: '400px' }, position: 'relative' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={applicationData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="applications" fill="#8884d8" radius={[4, 4, 0, 0]} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Skills Distribution
              </Typography>
              <Box sx={{ width: '100%', height: { xs: '300px', md: '400px' }, position: 'relative' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <Pie
                      data={skillProgressData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                      label
                    >
                      {skillProgressData.map((entry, index) => (
                        <Cell key={index} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={36} />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Applications Tab */}
      {tabValue === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="h6" gutterBottom>
                Application History
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Position</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Date Applied</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Match Score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Senior Software Engineer</TableCell>
                      <TableCell>Tech Solutions Inc.</TableCell>
                      <TableCell>2024-01-15</TableCell>
                      <TableCell>Under Review</TableCell>
                      <TableCell>95%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Systems Analyst</TableCell>
                      <TableCell>Defense Dynamics</TableCell>
                      <TableCell>2024-01-10</TableCell>
                      <TableCell>Interview Scheduled</TableCell>
                      <TableCell>88%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Skills Tab */}
      {tabValue === 2 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="h6" gutterBottom>
                Skills Assessment Progress
              </Typography>
              {skillProgressData.map((skill) => (
                <Box key={skill.name} sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>{skill.name}</Typography>
                    <Typography>{skill.value}%</Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={skill.value}
                    sx={{ 
                      height: 10, 
                      borderRadius: 5,
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 5,
                      }
                    }}
                  />
                </Box>
              ))}
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Job Matches Tab */}
      {tabValue === 3 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Job Match Distribution
              </Typography>
              <Box sx={{ width: '100%', height: { xs: '300px', md: '400px' }, position: 'relative' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <Pie
                      data={jobMatchData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                      label
                    >
                      {jobMatchData.map((entry, index) => (
                        <Cell key={index} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={36} />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="h6" gutterBottom>
                Recent Job Matches
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Position</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Match Score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Senior Software Engineer</TableCell>
                      <TableCell>Tech Solutions Inc.</TableCell>
                      <TableCell>95%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Systems Analyst</TableCell>
                      <TableCell>Defense Dynamics</TableCell>
                      <TableCell>88%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ReportsPage;
