import React, { useState } from 'react';
import { TextField, Button, Box, Paper, InputAdornment, Container } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SearchParams } from '../types';

interface JobSearchProps {
  onSearch: (params: SearchParams) => void;
}

const JobSearch: React.FC<JobSearchProps> = ({ onSearch }) => {
  const [keyword, setKeyword] = useState<string>('');
  const [location, setLocation] = useState<string>('');

  const handleSearch = () => {
    onSearch({ keyword, location });
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4, mb: 4, borderRadius: 2 }}>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2,
            width: '100%',
          }}
          onKeyPress={handleKeyPress}
        >
          <TextField
            fullWidth
            label="Search Jobs"
            variant="outlined"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Job title, keywords, or company"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Location"
            variant="outlined"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="City, state, or zip code"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{
              height: '56px',
              minWidth: { xs: '100%', md: '150px' },
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            Search Jobs
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default JobSearch;