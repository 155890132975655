import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@mui/material';
import PageHeader from '../../components/common/PageHeader';
import SaveIcon from '@mui/icons-material/Save';

const jobTypes = ['Full-time', 'Part-time', 'Contract', 'Temporary', 'Internship'];
const experienceLevels = ['Entry Level', 'Mid Level', 'Senior Level', 'Executive'];
const clearanceLevels = [
  'Public Trust',
  'Confidential',
  'Secret',
  'Top Secret',
  'TS/SCI',
  'TS/SCI with Poly',
];
const industries = [
  'Technology',
  'Defense & Security',
  'Healthcare',
  'Finance',
  'Manufacturing',
  'Logistics',
  'Government',
  'Consulting',
];
const commonSkills = [
  'JavaScript',
  'Python',
  'Java',
  'C++',
  'React',
  'Node.js',
  'AWS',
  'Azure',
  'Project Management',
  'Agile',
  'Security Clearance',
  'System Administration',
  'Data Analysis',
  'Machine Learning',
];
const educationLevels = [
  "High School",
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Ph.D.",
  "Certification",
];
const benefits = [
  'Health Insurance',
  'Dental Insurance',
  'Vision Insurance',
  '401(k)',
  'Life Insurance',
  'Paid Time Off',
  'Remote Work Options',
  'Flexible Schedule',
  'Professional Development',
  'Tuition Reimbursement',
];

const PostJobPage: React.FC = () => {
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);
  const jobStats = {
    drafts: 2,
    templates: 5,
    avgFillTime: '15 days'
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle form submission
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader
        title="Post a New Job"
        subtitle="Create and publish a new job listing"
        stats={[
          {
            value: jobStats.drafts,
            label: 'Saved Drafts',
            color: 'warning'
          },
          {
            value: jobStats.templates,
            label: 'Templates',
            color: 'info'
          },
          {
            value: jobStats.avgFillTime,
            label: 'Avg. Fill Time',
            color: 'success'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            sx={{
              backgroundColor: '#1a237e',
              '&:hover': {
                backgroundColor: '#0d47a1',
              }
            }}
          >
            Save as Draft
          </Button>
        }
      />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 4 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Basic Information */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Basic Information
                </Typography>
                <Divider sx={{ mb: 3 }} />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label="Job Title"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Job Type</InputLabel>
                  <Select label="Job Type" defaultValue="">
                    {jobTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Job Description"
                  variant="outlined"
                  multiline
                  rows={6}
                />
              </Grid>

              {/* Location and Requirements */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Location and Requirements
                </Typography>
                <Divider sx={{ mb: 3 }} />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label="Location"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remote Work Available"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Required Clearance Level</InputLabel>
                  <Select label="Required Clearance Level" defaultValue="">
                    {clearanceLevels.map((level) => (
                      <MenuItem key={level} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Experience Level</InputLabel>
                  <Select label="Experience Level" defaultValue="">
                    {experienceLevels.map((level) => (
                      <MenuItem key={level} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Industry</InputLabel>
                  <Select label="Industry" defaultValue="">
                    {industries.map((industry) => (
                      <MenuItem key={industry} value={industry}>
                        {industry}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Minimum Education</InputLabel>
                  <Select label="Minimum Education" defaultValue="">
                    {educationLevels.map((level) => (
                      <MenuItem key={level} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Skills */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Skills and Requirements
                </Typography>
                <Divider sx={{ mb: 3 }} />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={commonSkills}
                  value={selectedSkills}
                  onChange={(_, newValue) => setSelectedSkills(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Required Skills"
                      placeholder="Add skills"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={option}
                      />
                    ))
                  }
                />
              </Grid>

              {/* Compensation and Benefits */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Compensation and Benefits
                </Typography>
                <Divider sx={{ mb: 3 }} />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Salary Range (Min)"
                  variant="outlined"
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Salary Range (Max)"
                  variant="outlined"
                  type="number"
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={benefits}
                  value={selectedBenefits}
                  onChange={(_, newValue) => setSelectedBenefits(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Benefits"
                      placeholder="Add benefits"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={option}
                      />
                    ))
                  }
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Post Job
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default PostJobPage;
