import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Card,
  CardContent,
  CardActions,
  Chip,
  Tabs,
  Tab,
  LinearProgress,
} from '@mui/material';
import PageHeader from '../../components/common/PageHeader';

interface Course {
  title: string;
  description: string;
  duration: string;
  level: string;
  progress: number;
  status: 'not-started' | 'in-progress' | 'completed';
}

interface LearningPath {
  title: string;
  description: string;
  courses: Course[];
  totalHours: number;
  completionRate: number;
}

const learningPaths: { [key: string]: LearningPath } = {
  'cybersecurity': {
    title: 'Cybersecurity Professional',
    description: 'Master cybersecurity fundamentals and advanced security practices',
    totalHours: 120,
    completionRate: 35,
    courses: [
      {
        title: 'Security Fundamentals',
        description: 'Learn basic security concepts and principles',
        duration: '20 hours',
        level: 'Beginner',
        progress: 100,
        status: 'completed',
      },
      {
        title: 'Network Security',
        description: 'Understanding network protocols and security measures',
        duration: '25 hours',
        level: 'Intermediate',
        progress: 60,
        status: 'in-progress',
      },
      {
        title: 'Ethical Hacking',
        description: 'Learn penetration testing and vulnerability assessment',
        duration: '30 hours',
        level: 'Advanced',
        progress: 0,
        status: 'not-started',
      },
    ],
  },
  'software-development': {
    title: 'Software Development',
    description: 'Comprehensive path to become a full-stack developer',
    totalHours: 160,
    completionRate: 25,
    courses: [
      {
        title: 'Frontend Development',
        description: 'Master HTML, CSS, and JavaScript',
        duration: '40 hours',
        level: 'Beginner',
        progress: 100,
        status: 'completed',
      },
      {
        title: 'Backend Development',
        description: 'Learn server-side programming and databases',
        duration: '45 hours',
        level: 'Intermediate',
        progress: 30,
        status: 'in-progress',
      },
      {
        title: 'Cloud Services',
        description: 'Deploy and manage applications in the cloud',
        duration: '35 hours',
        level: 'Advanced',
        progress: 0,
        status: 'not-started',
      },
    ],
  },
  'data-science': {
    title: 'Data Science & Analytics',
    description: 'Learn to analyze and interpret complex data sets',
    totalHours: 140,
    completionRate: 15,
    courses: [
      {
        title: 'Data Analysis Basics',
        description: 'Introduction to data analysis and statistics',
        duration: '30 hours',
        level: 'Beginner',
        progress: 100,
        status: 'completed',
      },
      {
        title: 'Machine Learning',
        description: 'Fundamentals of ML algorithms and applications',
        duration: '40 hours',
        level: 'Intermediate',
        progress: 20,
        status: 'in-progress',
      },
      {
        title: 'Big Data Technologies',
        description: 'Working with large-scale data processing tools',
        duration: '35 hours',
        level: 'Advanced',
        progress: 0,
        status: 'not-started',
      },
    ],
  },
  'systems-engineering': {
    title: 'Systems Engineering',
    description: 'Learn to design and manage complex systems integration',
    totalHours: 150,
    completionRate: 0,
    courses: [
      {
        title: 'Systems Architecture',
        description: 'Fundamentals of systems design and architecture',
        duration: '35 hours',
        level: 'Beginner',
        progress: 0,
        status: 'not-started',
      },
      {
        title: 'Requirements Engineering',
        description: 'Learn to gather and manage system requirements',
        duration: '30 hours',
        level: 'Intermediate',
        progress: 0,
        status: 'not-started',
      },
      {
        title: 'System Integration & Testing',
        description: 'Advanced integration and testing methodologies',
        duration: '40 hours',
        level: 'Advanced',
        progress: 0,
        status: 'not-started',
      },
    ],
  },
  'cloud-architecture': {
    title: 'Cloud Architecture',
    description: 'Master cloud infrastructure and services',
    totalHours: 130,
    completionRate: 0,
    courses: [
      {
        title: 'Cloud Fundamentals',
        description: 'Introduction to cloud computing concepts',
        duration: '25 hours',
        level: 'Beginner',
        progress: 0,
        status: 'not-started',
      },
      {
        title: 'Cloud Security',
        description: 'Security best practices for cloud environments',
        duration: '35 hours',
        level: 'Intermediate',
        progress: 0,
        status: 'not-started',
      },
      {
        title: 'Multi-Cloud Strategy',
        description: 'Managing multi-cloud environments',
        duration: '40 hours',
        level: 'Advanced',
        progress: 0,
        status: 'not-started',
      },
    ],
  },
  'project-management': {
    title: 'Project Management',
    description: 'Learn to lead and manage technical projects',
    totalHours: 110,
    completionRate: 0,
    courses: [
      {
        title: 'Project Management Basics',
        description: 'Core concepts of project management',
        duration: '30 hours',
        level: 'Beginner',
        progress: 0,
        status: 'not-started',
      },
      {
        title: 'Agile Methodologies',
        description: 'Master Agile project management',
        duration: '40 hours',
        level: 'Intermediate',
        progress: 0,
        status: 'not-started',
      },
      {
        title: 'Risk Management',
        description: 'Advanced risk assessment and mitigation',
        duration: '30 hours',
        level: 'Advanced',
        progress: 0,
        status: 'not-started',
      },
    ],
  },
};

const LearningPathPage: React.FC = () => {
  const [selectedPath, setSelectedPath] = useState('cybersecurity');

  const handlePathChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedPath(newValue);
  };

  const currentPath = learningPaths[selectedPath];

  const completedCourses = currentPath.courses.filter(course => course.status === 'completed').length;
  const inProgressCourses = currentPath.courses.filter(course => course.status === 'in-progress').length;
  const overallProgress = currentPath.completionRate;

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader
        title="Learning Path"
        subtitle="Enhance your skills with personalized learning recommendations"
        stats={[
          {
            value: completedCourses,
            label: 'Completed',
            color: 'success'
          },
          {
            value: inProgressCourses,
            label: 'In Progress',
            color: 'warning'
          },
          {
            value: `${overallProgress}%`,
            label: 'Overall Progress',
            color: 'primary'
          }
        ]}
      />
      <Paper sx={{ mb: 3, borderRadius: '12px' }}>
        <Tabs 
          value={selectedPath} 
          onChange={handlePathChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-indicator': {
              height: 3,
              borderRadius: '3px',
            },
            '& .MuiTab-root': {
              minWidth: 120,
              fontSize: '0.9rem',
              textTransform: 'none',
              fontWeight: 500,
              py: 2,
              '&.Mui-selected': {
                fontWeight: 600,
              },
            },
          }}
        >
          <Tab label="Cybersecurity" value="cybersecurity" />
          <Tab label="Software Development" value="software-development" />
          <Tab label="Data Science" value="data-science" />
          <Tab label="Systems Engineering" value="systems-engineering" />
          <Tab label="Cloud Architecture" value="cloud-architecture" />
          <Tab label="Project Management" value="project-management" />
        </Tabs>
      </Paper>

      {currentPath && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 3, borderRadius: '12px', mb: 3 }}>
                <Typography variant="h5" gutterBottom>
                  {currentPath.title}
                </Typography>
                <Typography color="textSecondary" paragraph>
                  {currentPath.description}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="body2" sx={{ mr: 2 }}>
                    Overall Progress: {currentPath.completionRate}%
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={currentPath.completionRate}
                    sx={{ 
                      flexGrow: 1,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 4,
                      }
                    }}
                  />
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Total Duration: {currentPath.totalHours} hours
                </Typography>
              </Paper>
            </Grid>

            {currentPath.courses.map((course, index) => (
              <Grid item xs={12} md={4} key={course.title}>
                <Card sx={{ height: '100%', borderRadius: '12px' }}>
                  <CardContent>
                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Typography variant="h6" gutterBottom>
                        {course.title}
                      </Typography>
                      <Chip
                        label={course.level}
                        size="small"
                        color={
                          course.level === 'Beginner'
                            ? 'success'
                            : course.level === 'Intermediate'
                            ? 'primary'
                            : 'error'
                        }
                        sx={{ ml: 1 }}
                      />
                    </Box>
                    <Typography color="textSecondary" paragraph>
                      {course.description}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        Progress: {course.progress}%
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={course.progress}
                        sx={{ 
                          height: 6,
                          borderRadius: 3,
                          backgroundColor: 'rgba(0,0,0,0.1)',
                          '& .MuiLinearProgress-bar': {
                            borderRadius: 3,
                          }
                        }}
                      />
                    </Box>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                      Duration: {course.duration}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      variant={course.status === 'not-started' ? 'contained' : 'outlined'}
                      color={
                        course.status === 'completed'
                          ? 'success'
                          : course.status === 'in-progress'
                          ? 'primary'
                          : 'secondary'
                      }
                      fullWidth
                    >
                      {course.status === 'completed'
                        ? 'Review'
                        : course.status === 'in-progress'
                        ? 'Continue'
                        : 'Start Course'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default LearningPathPage;
