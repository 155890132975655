import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Container,
  Card,
  CardContent,
  useTheme,
  alpha,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SecurityIcon from '@mui/icons-material/Security';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ErrorIcon from '@mui/icons-material/Error';
import UpdateIcon from '@mui/icons-material/Update';
import WorkIcon from '@mui/icons-material/Work';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';

const clearanceGuides = [
  {
    title: 'Security Clearance Levels',
    icon: <SecurityIcon />,
    content: [
      'Confidential - Basic level clearance',
      'Secret - Intermediate level clearance',
      'Top Secret - Highest standard clearance',
      'TS/SCI - Special access to compartmented information',
    ],
    description: 'Each level requires progressively more thorough background investigations and provides access to increasingly sensitive information.',
  },
  {
    title: 'Application Process',
    icon: <AssignmentIcon />,
    content: [
      'Sponsorship by a government agency or contractor',
      'Submit SF-86 form (Questionnaire for National Security Positions)',
      'Background investigation',
      'Adjudication process',
      'Final determination',
    ],
    description: 'The security clearance process involves multiple steps and can take several months to complete.',
  },
  {
    title: 'Common Disqualifiers',
    icon: <ErrorIcon />,
    content: [
      'Recent drug use',
      'Significant financial problems',
      'Criminal record',
      'Foreign contacts or interests',
      'Past security violations',
    ],
    description: 'Each case is evaluated on its own merits, considering the "whole person concept."',
  },
  {
    title: 'Maintaining Your Clearance',
    icon: <UpdateIcon />,
    content: [
      'Report significant life changes',
      'Avoid unauthorized disclosure',
      'Complete periodic reinvestigations',
      'Follow security protocols',
      'Maintain financial responsibility',
    ],
    description: 'Maintaining your security clearance requires ongoing vigilance and responsibility.',
  },
  {
    title: 'Clearance Job Market',
    icon: <WorkIcon />,
    content: [
      'Higher compensation packages',
      'Specialized positions and roles',
      'Government and contractor opportunities',
      'Enhanced career advancement paths',
      'Long-term job security benefits',
    ],
    description: 'Security clearance opens up numerous high-value career opportunities.',
  }
];

const ClearanceGuidePage: React.FC = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<number | false>(0);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Page Header */}
      <Card 
        sx={{ 
          mb: 4,
          backgroundColor: alpha(theme.palette.primary.main, 0.03),
          border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        }}
      >
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h4" component="h1" sx={{ 
                fontWeight: 600,
                color: theme.palette.primary.main,
                mb: 1
              }}>
                Security Clearance Guide
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Essential information about security clearance levels, processes, and requirements
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                display: 'flex',
                gap: 2,
                justifyContent: 'flex-end'
              }}>
                <Button
                  variant="outlined"
                  startIcon={<PrintIcon />}
                  sx={{
                    borderColor: alpha(theme.palette.primary.main, 0.3),
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }
                  }}
                >
                  Print Guide
                </Button>
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  sx={{
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: `0 4px 8px ${alpha(theme.palette.primary.main, 0.2)}`,
                    }
                  }}
                >
                  Download PDF
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ 
            border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            boxShadow: `0 4px 6px -1px ${alpha(theme.palette.primary.main, 0.1)}`,
          }}>
            <CardContent>
              {clearanceGuides.map((guide, index) => (
                <Accordion 
                  key={index}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '&:before': {
                      display: 'none',
                    },
                    '&:not(:last-child)': {
                      borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    },
                  }}
                >
                  <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      '&.Mui-expanded': {
                        minHeight: 48,
                      },
                      '.MuiAccordionSummary-content.Mui-expanded': {
                        margin: '12px 0',
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box sx={{ 
                        color: theme.palette.primary.main,
                        display: 'flex',
                        p: 1,
                        borderRadius: 1,
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      }}>
                        {guide.icon}
                      </Box>
                      <Box>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {guide.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {guide.description}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List sx={{ pt: 0 }}>
                      {guide.content.map((item, itemIndex) => (
                        <ListItem key={itemIndex} sx={{ px: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <ArrowRightIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText 
                            primary={item}
                            primaryTypographyProps={{
                              variant: 'body1',
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClearanceGuidePage;
