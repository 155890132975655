import React from 'react';
import { Box, Container, useTheme, useMediaQuery } from '@mui/material';
import Header from '../components/common/Header';
import MobileHeader from '../components/common/MobileHeader';
import Sidebar from '../components/common/Sidebar';
import { getCandidateSidebarItems, getRecruiterSidebarItems } from '../components/common/Sidebar';

interface AppLayoutProps {
  children: React.ReactNode;
  userType: 'candidate' | 'recruiter';
}

export const SIDEBAR_WIDTH = 250;
export const HEADER_HEIGHT = 64;
export const MAX_CONTENT_WIDTH = 1440;

const AppLayout: React.FC<AppLayoutProps> = ({ children, userType }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const sidebarItems = userType === 'candidate' 
    ? getCandidateSidebarItems()
    : getRecruiterSidebarItems();

  return (
    <Box sx={{ 
      display: 'flex', 
      minHeight: '100vh',
      bgcolor: 'background.default',
      overflow: 'hidden',
    }}>
      {/* Desktop Header */}
      {!isMobile && <Header userType={userType} />}

      {/* Mobile Header */}
      {isMobile && <MobileHeader />}

      {/* Sidebar - Fixed position on desktop */}
      {!isMobile && (
        <Box
          component="nav"
          sx={{
            width: SIDEBAR_WIDTH,
            flexShrink: 0,
            position: 'fixed',
            height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            top: HEADER_HEIGHT,
            left: 0,
            overflowY: 'auto',
            bgcolor: 'background.paper',
            borderRight: 1,
            borderColor: 'divider',
            display: { xs: 'none', sm: 'none', md: 'block' },
            zIndex: theme.zIndex.drawer,
          }}
        >
          <Sidebar items={sidebarItems} />
        </Box>
      )}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          pt: isMobile ? `${HEADER_HEIGHT}px` : `${HEADER_HEIGHT + 24}px`,
          pl: { xs: 0, md: `${SIDEBAR_WIDTH}px` },
          pb: 4,
          width: '100%',
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            px: { xs: 2, sm: 3 },
            maxWidth: MAX_CONTENT_WIDTH,
            mx: 'auto',
          }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default AppLayout;
