import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, Paper, Grid, Button, Chip, Divider, Stack } from '@mui/material';
import { Email, Phone, LinkedIn, Description, School, Work } from '@mui/icons-material';

interface CandidateDetail {
  id: string;
  name: string;
  email: string;
  phone: string;
  linkedIn?: string;
  clearanceLevel: string;
  experience: number;
  education: string[];
  skills: string[];
  summary: string;
  workHistory: {
    company: string;
    position: string;
    duration: string;
    description: string;
  }[];
}

const CandidateDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  
  // This would typically come from an API call
  const candidateData: CandidateDetail = {
    id: id || '1',
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '(555) 123-4567',
    linkedIn: 'linkedin.com/in/johndoe',
    clearanceLevel: 'Top Secret/SCI',
    experience: 8,
    education: [
      'Master of Science in Computer Science, Stanford University',
      'Bachelor of Science in Software Engineering, MIT'
    ],
    skills: ['Java', 'Python', 'React', 'Node.js', 'AWS', 'Docker', 'Kubernetes'],
    summary: 'Experienced software engineer with a strong background in full-stack development and cloud architecture. Proven track record of delivering secure, scalable solutions for government and commercial clients.',
    workHistory: [
      {
        company: 'Tech Solutions Inc.',
        position: 'Senior Software Engineer',
        duration: '2019 - Present',
        description: 'Lead developer for classified government projects, focusing on secure communication systems and data processing pipelines.'
      },
      {
        company: 'Government Systems LLC',
        position: 'Software Engineer',
        duration: '2015 - 2019',
        description: 'Developed and maintained critical infrastructure applications for various government agencies.'
      }
    ]
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        {/* Header Section */}
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Typography variant="h4" component="h1">
              {candidateData.name}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Chip 
                label={candidateData.clearanceLevel}
                color="primary"
              />
              <Chip 
                label={`${candidateData.experience} Years Experience`}
                color="secondary"
              />
            </Stack>
          </Stack>

          <Grid container spacing={4}>
            {/* Left Column */}
            <Grid item xs={12} md={4}>
              <Stack spacing={3}>
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    <Typography variant="h6" component="h2">Contact Information</Typography>
                    <Stack spacing={2}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Email />
                        <Typography component="span">{candidateData.email}</Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Phone />
                        <Typography component="span">{candidateData.phone}</Typography>
                      </Stack>
                      {candidateData.linkedIn && (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <LinkedIn />
                          <Typography component="span">{candidateData.linkedIn}</Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Paper>

                <Paper elevation={2} sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    <Typography variant="h6" component="h2">Skills</Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap">
                      {candidateData.skills.map((skill) => (
                        <Chip key={skill} label={skill} size="small" />
                      ))}
                    </Stack>
                  </Stack>
                </Paper>

                <Paper elevation={2} sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    <Typography variant="h6" component="h2">Education</Typography>
                    <Stack spacing={1}>
                      {candidateData.education.map((edu, index) => (
                        <Stack key={index} direction="row" spacing={1} alignItems="center">
                          <School fontSize="small" />
                          <Typography component="span">{edu}</Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={8}>
              <Stack spacing={3}>
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    <Typography variant="h6" component="h2">Professional Summary</Typography>
                    <Typography component="p">{candidateData.summary}</Typography>
                  </Stack>
                </Paper>

                <Paper elevation={2} sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    <Typography variant="h6" component="h2">Work History</Typography>
                    <Stack spacing={2}>
                      {candidateData.workHistory.map((work, index) => (
                        <Stack key={index} spacing={1}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Work />
                            <Typography variant="h6" component="h3">{work.position}</Typography>
                          </Stack>
                          <Typography variant="subtitle1" component="p" color="primary">{work.company}</Typography>
                          <Typography variant="subtitle2" component="p" color="text.secondary">{work.duration}</Typography>
                          <Typography component="p">{work.description}</Typography>
                          {index < candidateData.workHistory.length - 1 && (
                            <Divider sx={{ my: 2 }} />
                          )}
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
          </Grid>

          {/* Action Buttons */}
          <Stack spacing={2} direction="row">
            <Button variant="contained" color="primary">
              Contact Candidate
            </Button>
            <Button variant="outlined" color="primary">
              Download Resume
            </Button>
            <Button variant="outlined" color="secondary">
              Save Profile
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
};

export default CandidateDetailPage;
