import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Button,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PageHeader from '../../components/common/PageHeader';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const hiringData = [
  { month: 'Jan', hires: 4 },
  { month: 'Feb', hires: 3 },
  { month: 'Mar', hires: 6 },
  { month: 'Apr', hires: 8 },
  { month: 'May', hires: 5 },
  { month: 'Jun', hires: 7 },
];

const sourcingData = [
  { name: 'Job Boards', value: 35 },
  { name: 'Referrals', value: 25 },
  { name: 'Direct', value: 20 },
  { name: 'LinkedIn', value: 20 },
];

const timeToHireData = [
  { stage: '0-15 days', count: 10 },
  { stage: '15-30 days', count: 15 },
  { stage: '30-45 days', count: 8 },
  { stage: '45+ days', count: 5 },
];

const ReportsPage: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const totalApplications = 100;
  const hireRate = 25;
  const timeToHire = '30 days';

  const handleExportReport = () => {
    // Add export report logic here
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader
        title="Reports & Analytics"
        subtitle="Track recruitment metrics and performance analytics"
        stats={[
          {
            value: totalApplications,
            label: 'Applications',
            color: 'primary'
          },
          {
            value: `${hireRate}%`,
            label: 'Hire Rate',
            color: 'success'
          },
          {
            value: `${timeToHire}`,
            label: 'Avg. Time to Hire',
            color: 'info'
          }
        ]}
        action={
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={handleExportReport}
          >
            Export Report
          </Button>
        }
      />

      <Paper sx={{ mb: 3, borderRadius: '12px' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-indicator': {
              height: 3,
              borderRadius: '3px',
            },
            '& .MuiTab-root': {
              minWidth: 120,
              fontSize: '0.9rem',
              textTransform: 'none',
              fontWeight: 500,
              py: 2,
              '&.Mui-selected': {
                fontWeight: 600,
              },
            },
          }}
        >
          <Tab label="Overview" />
          <Tab label="Hiring Pipeline" />
          <Tab label="Sourcing" />
          <Tab label="Time to Hire" />
        </Tabs>
      </Paper>

      {/* Overview Tab */}
      {tabValue === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Monthly Hiring Trends
              </Typography>
              <Box sx={{ width: '100%', height: { xs: '300px', md: '400px' }, position: 'relative' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={hiringData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="hires" fill="#8884d8" radius={[4, 4, 0, 0]} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Candidate Sources
              </Typography>
              <Box sx={{ width: '100%', height: { xs: '300px', md: '400px' }, position: 'relative' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <Pie
                      data={sourcingData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                      label
                    >
                      {sourcingData.map((entry, index) => (
                        <Cell key={index} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={36} />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Hiring Pipeline Tab */}
      {tabValue === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="h6" gutterBottom>
                Active Candidates by Stage
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Position</TableCell>
                      <TableCell>Total Candidates</TableCell>
                      <TableCell>Screening</TableCell>
                      <TableCell>Interview</TableCell>
                      <TableCell>Offer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Software Engineer</TableCell>
                      <TableCell>25</TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>8</TableCell>
                      <TableCell>2</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Systems Analyst</TableCell>
                      <TableCell>18</TableCell>
                      <TableCell>8</TableCell>
                      <TableCell>5</TableCell>
                      <TableCell>1</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Sourcing Tab */}
      {tabValue === 2 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="h6" gutterBottom>
                Source Effectiveness
              </Typography>
              {sourcingData.map((source) => (
                <Box key={source.name} sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>{source.name}</Typography>
                    <Typography>{source.value}%</Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={source.value}
                    sx={{ 
                      height: 10, 
                      borderRadius: 5,
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 5,
                      }
                    }}
                  />
                </Box>
              ))}
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Time to Hire Tab */}
      {tabValue === 3 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Time to Hire Distribution
              </Typography>
              <Box sx={{ width: '100%', height: { xs: '300px', md: '400px' }, position: 'relative' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={timeToHireData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="stage" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#8884d8" radius={[4, 4, 0, 0]} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ReportsPage;
