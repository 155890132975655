import React from 'react';
import { Box, Typography, Paper, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PageHeader from '../../components/common/PageHeader';

const EmailTemplatesPage: React.FC = () => {
  const templateStats = {
    totalTemplates: 12,
    recentlySent: 45,
    responseRate: '68%'
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader
        title="Email Templates"
        subtitle="Manage and customize your email templates"
        stats={[
          {
            value: templateStats.totalTemplates,
            label: 'Templates',
            color: 'primary'
          },
          {
            value: templateStats.recentlySent,
            label: 'Recently Sent',
            color: 'info'
          },
          {
            value: templateStats.responseRate,
            label: 'Response Rate',
            color: 'success'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: '#1a237e',
              '&:hover': {
                backgroundColor: '#0d47a1',
              }
            }}
          >
            Create Template
          </Button>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Interview Invitation</Typography>
              <Typography variant="body2" color="text.secondary">
                Template for inviting candidates to interviews
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Edit</Button>
              <Button size="small">Use Template</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Offer Letter</Typography>
              <Typography variant="body2" color="text.secondary">
                Standard offer letter template
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Edit</Button>
              <Button size="small">Use Template</Button>
            </CardActions>
          </Card>
        </Grid>
        {/* Add more template cards as needed */}
      </Grid>
    </Box>
  );
};

export default EmailTemplatesPage;
