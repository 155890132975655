import React from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from '../components/common/Header';
import Sidebar, { getCandidateSidebarItems } from '../components/common/Sidebar';
import Footer from '../components/Footer';
import { SIDEBAR_WIDTH, HEADER_HEIGHT, MAX_CONTENT_WIDTH } from './AppLayout';

interface CandidateLayoutProps {
  children?: React.ReactNode;
}

const CandidateLayout: React.FC<CandidateLayoutProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      overflow: 'hidden',
    }}>
      <Header userType="candidate" />
      <Box sx={{ display: 'flex', flex: 1 }}>
        {!isMobile && (
          <Box
            component="nav"
            sx={{
              width: SIDEBAR_WIDTH,
              flexShrink: 0,
              position: 'fixed',
              height: `calc(100vh - ${HEADER_HEIGHT}px)`,
              top: HEADER_HEIGHT,
              left: 0,
              overflowY: 'auto',
              bgcolor: 'background.paper',
              borderRight: 1,
              borderColor: 'divider',
              display: { xs: 'none', sm: 'none', md: 'block' },
              zIndex: theme.zIndex.drawer,
            }}
          >
            <Sidebar items={getCandidateSidebarItems()} />
          </Box>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
            mt: `${HEADER_HEIGHT}px`,
            ml: { xs: 0, sm: 0, md: `${SIDEBAR_WIDTH}px` },
            width: {
              xs: '100%',
              sm: '100%',
              md: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            },
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Container 
            maxWidth={false}
            sx={{ 
              height: '100%',
              p: { xs: 2, sm: 3, md: 4 },
              maxWidth: {
                xs: '100%',
                sm: '100%',
                md: `min(${MAX_CONTENT_WIDTH}px, calc(100% - ${theme.spacing(4)}))`,
                lg: `min(${MAX_CONTENT_WIDTH}px, calc(100% - ${theme.spacing(6)}))`,
              },
              mx: 'auto',
            }}
          >
            <Outlet />
          </Container>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default CandidateLayout;
