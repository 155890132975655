import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  Grid,
  Button,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  useTheme,
  alpha,
  TextField,
  InputAdornment,
  Stack,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import WorkIcon from '@mui/icons-material/Work';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchFilters from '../../components/JobSearch/SearchFilters';
import JobCard from '../../components/JobSearch/JobCard';
import PageHeader from '../../components/common/PageHeader';
import { JobCardProps } from '../../types/job';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`jobs-tabpanel-${index}`}
      aria-labelledby={`jobs-tab-${index}`}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};

const JobsPage: React.FC = () => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [location, setLocation] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState('timestamp+desc');
  const [perPage, setPerPage] = useState(20);
  const [showFilters, setShowFilters] = useState(false);

  const [activeFilters, setActiveFilters] = useState<{
    experience: string[];
    cities: string[];
    states: string[];
    clearance: string[];
    postedWithin: string[];
    salary: string[];
    securityRequirements: string[];
    workplaceType: string[];
    employmentType: string[];
    jobCategory: string[];
    education: string[];
    travelRequired: string[];
    citizenship: string[];
  }>({
    experience: [],
    cities: [],
    states: [],
    clearance: [],
    postedWithin: [],
    salary: [],
    securityRequirements: [],
    workplaceType: [],
    employmentType: [],
    jobCategory: [],
    education: [],
    travelRequired: [],
    citizenship: [],
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleRemoveFilter = (filter: string) => {
    setSelectedFilters(filters => filters.filter(f => f !== filter));
  };

  const handleFiltersChange = (newFilters: typeof activeFilters) => {
    setActiveFilters(newFilters);
    const allFilters = Object.values(newFilters).flat();
    setSelectedFilters(allFilters);
  };

  // Mock data for different job lists
  const mockAvailableJobs: JobCardProps[] = [
    {
      id: '1',
      title: 'Senior Software Engineer',
      company: 'TechCorp Solutions',
      location: 'Washington, DC',
      isOnSite: true,
      updatedAt: '2023-12-10',
      experience: '5+ years',
      clearance: 'Top Secret/SCI',
      description: 'Looking for an experienced software engineer with strong background in cloud computing and security systems.',
      isNew: true,
      recruiterName: 'John Smith',
      recruiterInitial: 'JS',
      recruiterColor: '#1976d2',
      salary: '$120,000 - $180,000',
      clearanceLevel: 'Top Secret/SCI',
      postDate: '2023-12-01',
      certifications: ['AWS', 'Security+', 'CISSP'],
      experienceYears: '5+',
      education: "Bachelor's Degree",
      jobType: 'Full-time',
      companyLogo: '/images/techcorp-logo.png',
      companySize: '1000-5000'
    },
  ];

  const mockInterestedJobs: JobCardProps[] = [
    {
      id: '2',
      title: 'Cloud Security Engineer',
      company: 'SecureCloud Inc.',
      location: 'Arlington, VA',
      isOnSite: false,
      updatedAt: '2023-12-09',
      experience: '3+ years',
      clearance: 'Secret',
      description: 'Join our cloud security team to help protect critical infrastructure.',
      isNew: false,
      recruiterName: 'Jane Doe',
      recruiterInitial: 'JD',
      recruiterColor: '#2e7d32',
      salary: '$100,000 - $150,000',
      clearanceLevel: 'Secret',
      postDate: '2023-12-05',
      certifications: ['AWS', 'Azure', 'Security+'],
      experienceYears: '3+',
      education: "Bachelor's Degree",
      jobType: 'Full-time',
      companyLogo: '/images/securecloud-logo.png',
      companySize: '500-1000'
    },
  ];

  const mockAppliedJobs: JobCardProps[] = [
    {
      id: '3',
      title: 'DevSecOps Engineer',
      company: 'Defense Systems Corp',
      location: 'Alexandria, VA',
      isOnSite: true,
      updatedAt: '2023-12-08',
      experience: '4+ years',
      clearance: 'TS/SCI with Poly',
      description: 'Looking for a DevSecOps engineer to help automate our security processes.',
      isNew: false,
      recruiterName: 'Mike Johnson',
      recruiterInitial: 'MJ',
      recruiterColor: '#ed6c02',
      salary: '$130,000 - $170,000',
      clearanceLevel: 'TS/SCI with Poly',
      postDate: '2023-12-03',
      certifications: ['Security+', 'CISSP', 'AWS'],
      experienceYears: '4+',
      education: "Master's Degree",
      jobType: 'Full-time',
      companyLogo: '/images/defense-systems-logo.png',
      companySize: '5000+'
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader
        title="Find Your Next Position"
        subtitle="Browse through thousands of clearance-level positions tailored to your profile"
        stats={[
          {
            value: mockAvailableJobs.length,
            label: 'Available Jobs',
            color: 'primary'
          },
          {
            value: '95%',
            label: 'Match Rate',
            color: 'success'
          },
          {
            value: mockAppliedJobs.length,
            label: 'Applied',
            color: 'info'
          }
        ]}
      />

      {/* Search Section */}
      <Card 
        elevation={0}
        sx={{ 
          mb: 4, 
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          backdropFilter: 'blur(20px)',
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                placeholder="Job title, skills, or keywords"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                placeholder="City, state, or remote"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={() => setShowFilters(true)}
                startIcon={<FilterListIcon />}
                sx={{
                  height: '100%',
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                Filters
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Results Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Available Positions
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Viewing 1 - {perPage} of 1,234
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Show</InputLabel>
            <Select
              value={perPage}
              label="Show"
              onChange={(e) => setPerPage(Number(e.target.value))}
            >
              <MenuItem value={10}>10 per page</MenuItem>
              <MenuItem value={20}>20 per page</MenuItem>
              <MenuItem value={50}>50 per page</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Sort by</InputLabel>
            <Select
              value={sortBy}
              label="Sort by"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value="timestamp+desc">Date (Newest)</MenuItem>
              <MenuItem value="timestamp+asc">Date (Oldest)</MenuItem>
              <MenuItem value="relevance">Relevance</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box>

      {/* Job Cards */}
      <Stack spacing={2}>
        {mockAvailableJobs.map((job) => (
          <JobCard key={job.id} {...job} />
        ))}
      </Stack>

      {/* Filters Dialog */}
      <SearchFilters
        open={showFilters}
        onClose={() => setShowFilters(false)}
        filters={activeFilters}
        onFiltersChange={handleFiltersChange}
      />
    </Container>
  );
};

export default JobsPage;
