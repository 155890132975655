import React from 'react';
import { Box, Typography, Paper, Grid, Switch, FormControlLabel, TextField, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PageHeader from '../../components/common/PageHeader';

const SettingsPage: React.FC = () => {
  const settingsStats = {
    emailTemplates: 8,
    automationRules: 5,
    integrations: 3
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader
        title="Settings"
        subtitle="Configure your recruitment workflow and preferences"
        stats={[
          {
            value: settingsStats.emailTemplates,
            label: 'Templates',
            color: 'primary'
          },
          {
            value: settingsStats.automationRules,
            label: 'Automations',
            color: 'success'
          },
          {
            value: settingsStats.integrations,
            label: 'Integrations',
            color: 'info'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<SettingsIcon />}
          >
            System Settings
          </Button>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Notification Preferences
            </Typography>
            <Box sx={{ mb: 3 }}>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Email notifications for new applications"
              />
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Email notifications for interview confirmations"
              />
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Email notifications for team messages"
              />
            </Box>

            <Typography variant="h6" gutterBottom>
              Email Settings
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Email Signature"
                multiline
                rows={4}
                defaultValue="Best regards,&#10;[Your Name]&#10;[Company Name]"
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary">
                Save Changes
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsPage;
