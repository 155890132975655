import React from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Card, 
  CardContent, 
  Button, 
  LinearProgress,
  Container,
  useTheme,
  alpha,
  IconButton,
  Tooltip,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimerIcon from '@mui/icons-material/Timer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PageHeader from '../../components/common/PageHeader';

const assessments = [
  {
    title: 'Technical Skills',
    description: 'Evaluate your programming and technical expertise',
    icon: '💻',
    categories: [
      { name: 'JavaScript', progress: 85, completed: true, time: '45 min', questions: 30 },
      { name: 'Python', progress: 90, completed: true, time: '60 min', questions: 35 },
      { name: 'System Design', progress: 0, completed: false, time: '90 min', questions: 25 },
    ],
  },
  {
    title: 'Security Clearance Knowledge',
    description: 'Test your understanding of security protocols and procedures',
    icon: '🔒',
    categories: [
      { name: 'Security Protocols', progress: 75, completed: true, time: '30 min', questions: 20 },
      { name: 'Clearance Levels', progress: 100, completed: true, time: '25 min', questions: 15 },
      { name: 'Security Best Practices', progress: 0, completed: false, time: '45 min', questions: 25 },
    ],
  },
  {
    title: 'Soft Skills',
    description: 'Assess your interpersonal and professional capabilities',
    icon: '🤝',
    categories: [
      { name: 'Communication', progress: 90, completed: true, time: '40 min', questions: 30 },
      { name: 'Problem Solving', progress: 0, completed: false, time: '50 min', questions: 25 },
      { name: 'Team Collaboration', progress: 0, completed: false, time: '35 min', questions: 20 },
    ],
  },
];

const SkillAssessmentsPage: React.FC = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader
        title="Skill Assessments"
        subtitle="Showcase your expertise and stand out to employers by completing skill assessments"
        stats={[
          {
            value: '6',
            label: 'Badges Earned',
            color: 'primary'
          },
          {
            value: '85%',
            label: 'Avg. Score',
            color: 'success'
          }
        ]}
      />
      <Grid container spacing={3}>
        {assessments.map((section, index) => (
          <Grid item xs={12} key={index}>
            <Card 
              sx={{ 
                border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                boxShadow: `0 4px 6px -1px ${alpha(theme.palette.primary.main, 0.1)}`,
              }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                  <Typography variant="h2" sx={{ mr: 2, fontSize: '2rem' }}>
                    {section.icon}
                  </Typography>
                  <Box>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                      {section.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {section.description}
                    </Typography>
                  </Box>
                </Box>
                
                <Grid container spacing={3}>
                  {section.categories.map((category, catIndex) => (
                    <Grid item xs={12} md={4} key={catIndex}>
                      <Card 
                        sx={{ 
                          height: '100%',
                          backgroundColor: category.completed 
                            ? alpha(theme.palette.success.main, 0.05)
                            : 'white',
                          transition: 'transform 0.2s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                          },
                        }}
                      >
                        <CardContent>
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            mb: 2
                          }}>
                            <Typography variant="h6">
                              {category.name}
                            </Typography>
                            {category.completed && (
                              <Tooltip title="Completed">
                                <CheckCircleIcon 
                                  sx={{ 
                                    color: theme.palette.success.main,
                                    fontSize: 24
                                  }} 
                                />
                              </Tooltip>
                            )}
                          </Box>
                          
                          {category.completed ? (
                            <>
                              <LinearProgress 
                                variant="determinate" 
                                value={category.progress} 
                                sx={{ 
                                  height: 8, 
                                  borderRadius: 4,
                                  mb: 2,
                                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: theme.palette.primary.main,
                                  }
                                }}
                              />
                              <Typography 
                                variant="h5" 
                                sx={{ 
                                  textAlign: 'center',
                                  color: theme.palette.primary.main,
                                  fontWeight: 600,
                                  mb: 2
                                }}
                              >
                                {category.progress}%
                              </Typography>
                            </>
                          ) : (
                            <Box sx={{ textAlign: 'center', mb: 2 }}>
                              <Button 
                                variant="contained" 
                                color="primary"
                                startIcon={<PlayArrowIcon />}
                                sx={{
                                  textTransform: 'none',
                                  fontWeight: 600,
                                  boxShadow: 'none',
                                  '&:hover': {
                                    boxShadow: `0 4px 8px ${alpha(theme.palette.primary.main, 0.2)}`,
                                  }
                                }}
                              >
                                Start Assessment
                              </Button>
                            </Box>
                          )}
                          
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-around',
                            color: 'text.secondary',
                            borderTop: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            pt: 2
                          }}>
                            <Box sx={{ textAlign: 'center' }}>
                              <TimerIcon sx={{ fontSize: 20, mb: 0.5 }} />
                              <Typography variant="body2">
                                {category.time}
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                {category.questions}
                              </Typography>
                              <Typography variant="body2">
                                Questions
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SkillAssessmentsPage;
