import React from 'react';
import { Navigate } from 'react-router-dom';
import JobsPage from '../pages/candidate/JobsPage';
import JobDetailPage from '../pages/candidate/JobDetailPage';
import SavedJobsPage from '../pages/candidate/SavedJobsPage';
import ProfilePage from '../pages/candidate/ProfilePage';
import MessagesPage from '../pages/candidate/MessagesPage';
import NotificationsPage from '../pages/shared/NotificationsPage';
import ApplicationTrackingPage from '../pages/candidate/ApplicationTrackingPage';
import CandidateDashboardPage from '../pages/candidate/DashboardPage';
import DocumentsPage from '../pages/candidate/DocumentsPage';
import SkillAssessmentsPage from '../pages/candidate/SkillAssessmentsPage';
import CareerResourcesPage from '../pages/candidate/CareerResourcesPage';
import ClearanceGuidePage from '../pages/candidate/ClearanceGuidePage';
import PreferencesPage from '../pages/candidate/PreferencesPage';
import LearningPathPage from '../pages/candidate/LearningPathPage';
import CandidateReportsPage from '../pages/candidate/ReportsPage';

export const candidateRoutes = [
  {
    path: "",
    element: <Navigate to="dashboard" replace />
  },
  {
    path: "dashboard",
    element: <CandidateDashboardPage />
  },
  {
    path: "jobs",
    element: <JobsPage />
  },
  {
    path: "jobs/:jobId",
    element: <JobDetailPage />
  },
  {
    path: "saved-jobs",
    element: <SavedJobsPage />
  },
  {
    path: "applications",
    element: <ApplicationTrackingPage />
  },
  {
    path: "documents",
    element: <DocumentsPage />
  },
  {
    path: "profile",
    element: <ProfilePage />
  },
  {
    path: "messages",
    element: <MessagesPage />
  },
  {
    path: "notifications",
    element: <NotificationsPage />
  },
  {
    path: "assessments",
    element: <SkillAssessmentsPage />
  },
  {
    path: "resources",
    element: <CareerResourcesPage />
  },
  {
    path: "clearance-guide",
    element: <ClearanceGuidePage />
  },
  {
    path: "preferences",
    element: <PreferencesPage />
  },
  {
    path: "learning-path",
    element: <LearningPathPage />
  },
  {
    path: "reports",
    element: <CandidateReportsPage />
  }
];
