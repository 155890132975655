import React from 'react';
import { Box, Typography, Paper, Grid, Card, CardContent, Chip } from '@mui/material';
import PageHeader from '../../components/common/PageHeader';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const InterviewsPage: React.FC = () => {
  const [scheduleDialogOpen, setScheduleDialogOpen] = React.useState(false);
  const interviews = []; // Assuming interviews data is available here

  return (
    <Box>
      <PageHeader
        title="Interviews"
        subtitle="Schedule and manage candidate interviews"
        stats={[
          {
            value: interviews.length,
            label: 'Total Interviews',
            color: 'primary'
          },
          {
            value: interviews.filter(i => new Date(i.date) > new Date()).length,
            label: 'Upcoming',
            color: 'warning'
          },
          {
            value: interviews.filter(i => i.status === 'completed').length,
            label: 'Completed',
            color: 'success'
          }
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setScheduleDialogOpen(true)}
          >
            Schedule Interview
          </Button>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Upcoming Interviews
            </Typography>
            {/* Sample interview cards */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">John Smith</Typography>
                    <Typography color="textSecondary" gutterBottom>
                      Senior Software Engineer Position
                    </Typography>
                    <Typography variant="body2">
                      Date: December 15, 2024
                      <br />
                      Time: 2:00 PM EST
                      <br />
                      Type: Technical Interview
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Chip label="Scheduled" color="primary" size="small" />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              {/* Add more interview cards as needed */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InterviewsPage;
